const prices = [
  {
    key: "basic",
    name: "Basic",
    price: "30.00",
    period: "onetime",
    priceId: "price_1QJa9rI6ZENpEKNH3wsdELMl",
    features: [
      {
        key: "1",
        content: "Pre-Generated: 15 Unique Backgrounds",
      },
      {
        key: "2",
        content: "Pre-Generated: 15 Unique Clothing Styles",
      },
      {
        key: "6",
        content: "Pre-Generated: 15 Different Poses",
      },
      {
        key: "3",
        content: "Custom Image Genenrator: 10 Credits",
      },
      {
        key: "4",
        content: "Training Model: 1 credit",
      },
      {
        key: "5",
        content: "Turnaround Time: Less than 30 Minutes",
      },
      {
        key: "7",
        content: "Dating Ice Breaker: 300 Credits",
      },
      {
        key: "8",
        content: "Dating Bio Generator: 300 Credits",
      },
      {
        key: "9",
        content: "Dating Reply Enhancer: 300 Credits",
      },
      {
        key: "10",
        content: "Money-back Guarantee",
      },
    ],
  },
  {
    key: "standard",
    name: "Standard",
    price: "50.00",
    period: "onetime",
    priceId: "price_1QJaDyI6ZENpEKNHBk2tUcoE",
    features: [
      {
        key: "1",
        content: "Pre-Generated: 30 Unique Backgrounds",
      },
      {
        key: "2",
        content: "Pre-Generated: 30 Unique Clothing Styles",
      },
      {
        key: "6",
        content: "Pre-Generated: 30 Different Poses",
      },
      {
        key: "3",
        content: "Custom Image Generation: 25 Credits",
      },
      {
        key: "4",
        content: "Training Model: 2 Credits",
      },
      {
        key: "5",
        content: "Turnaround Time: Less than 30 Minutes",
      },
      {
        key: "7",
        content: "Dating Ice Breaker: 700 Credits",
      },
      {
        key: "8",
        content: "Dating Bio Generator: 700 Credits",
      },
      {
        key: "9",
        content: "Dating Reply Enhancer: 700 Credits",
      },
      {
        key: "10",
        content: "Money-back Guarantee",
      },
    ],
  },
  {
    key: "premium",
    name: "Premium",
    price: "79.00",
    period: "onetime",
    priceId: "price_1QJaCQI6ZENpEKNHBOTVLWVw",
    features: [
      {
        key: "1",
        content: "Pre-Generated: 60 Unique Backgrounds",
      },
      {
        key: "2",
        content: "Pre-Generated: 60 Unique Clothing Styles",
      },
      {
        key: "6",
        content: "Pre-Generated: 60 Different Poses",
      },
      {
        key: "3",
        content: "Custom Image Generation: 50 Credits",
      },
      {
        key: "4",
        content: "Training Model: 4 credits",
      },
      {
        key: "5",
        content: "Turnaround Time: Less than 30 Minutes",
      },
      {
        key: "7",
        content: "Dating Ice Breaker: 1000 Credits",
      },
      {
        key: "8",
        content: "Dating Bio Generator: 1000 Credits",
      },
      {
        key: "9",
        content: "Dating Reply Enhancer: 1000 Credits",
      },
      {
        key: "10",
        content: "Money-back Guarantee",
      },
    ],
  },
];

export default prices;
