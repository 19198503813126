import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <>
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Effective Date: September 16, 2024
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            Introduction
          </Typography>
          <Typography paragraph>
            RizzShot ("we", "our", or "us") respects your privacy and is committed to protecting the personal data you share with us. This Privacy Policy explains how we collect, use, and disclose information when you use our services, including our website (www.rizzshot.ai) and any other related services (collectively, the "Service").
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            1. Information We Collect
          </Typography>
          <Typography paragraph>
            We collect the following types of information:
          </Typography>
          <Typography component="ul">
            <li>
              <strong>Personal Information:</strong> When you sign up for an account, use the Service, or communicate with us, we may collect personal information such as your name, email address, billing information, and other contact details.
            </li>
            <li>
              <strong>Usage Data:</strong> We collect information about how you interact with the Service, such as your IP address, device information, browser type, pages viewed, and actions taken on our platform.
            </li>
            <li>
              <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience, track user activity, and gather information about our users. You can control cookie settings through your browser.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            2. How We Use Your Information
          </Typography>
          <Typography paragraph>
            We use the information we collect for the following purposes:
          </Typography>
          <Typography component="ul">
            <li>To provide, operate, and improve the Service.</li>
            <li>To process transactions, manage subscriptions, and provide customer support.</li>
            <li>To communicate with you, including sending you updates, notifications, and promotional materials.</li>
            <li>To analyze usage patterns and improve the performance of our app.</li>
            <li>To comply with legal obligations and protect the security of our platform.</li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            3. Sharing Your Information
          </Typography>
          <Typography paragraph>
            We do not sell or rent your personal information. However, we may share your information in the following cases:
          </Typography>
          <Typography component="ul">
            <li>
              <strong>Service Providers:</strong> We may share your information with third-party service providers who help us operate the Service, process payments, or provide customer support.
            </li>
            <li>
              <strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid legal requests.
            </li>
            <li>
              <strong>Business Transfers:</strong> If RizzShot is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.
            </li>
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            4. Data Security
          </Typography>
          <Typography paragraph>
            We use reasonable technical and organizational measures to protect your personal information. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            5. Your Rights
          </Typography>
          <Typography paragraph>
            Depending on your location, you may have certain rights regarding your personal data, including:
          </Typography>
          <Typography component="ul">
            <li><strong>Access:</strong> You can request access to your personal information.</li>
            <li><strong>Correction:</strong> You can request corrections to your data if it is inaccurate.</li>
            <li><strong>Deletion:</strong> You can request the deletion of your personal information.</li>
            <li><strong>Opt-Out:</strong> You may opt out of receiving promotional communications from us by following the instructions in those communications.</li>
          </Typography>
          <Typography paragraph>
            To exercise these rights, please contact us at [insert contact email].
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            6. Data Retention
          </Typography>
          <Typography paragraph>
            We retain your personal data for as long as necessary to provide the Service, comply with legal obligations, and resolve disputes.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            7. Changes to this Privacy Policy
          </Typography>
          <Typography paragraph>
            We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our website.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            8. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about this Privacy Policy, please contact us at support@rizzshot.ai
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicy;