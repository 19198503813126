import { useRef } from "react";
import Compare from "./landing/Compare";
import Gallery from "./landing/Gallery";
import Hero from "./landing/Hero";
import Steps from "./landing/Steps";
import Header from "./layout/GHeader";
import Faq from "./landing/Faq";
import Pricing from "./landing/Pricing";
import Trust from "./landing/Trust";
import Try from "./landing/Try";
import Footer from "./layout/GFooter";
import Banner from "./layout/Banner";

export default function Homepage() {
  const galleryRef = useRef();
  const pricingRef = useRef();
  const faqRef = useRef();
  const bannerRef = useRef();

  return (
    <div className="relative">
      <Banner bannerRef={bannerRef} />
      <Header pricingRef={pricingRef} bannerRef={bannerRef} />
      <Hero galleryRef={galleryRef} />
      <Steps />
      <Compare />
      <Gallery galleryRef={galleryRef} />
      {/* <LiveDemo /> */}
      <div id="pricing" ref={pricingRef}>
        <Pricing pricingRef={pricingRef} />
      </div>
      <Faq faqRef={faqRef} />
      <Trust />
      <Try />
      <Footer pricingRef={pricingRef} faqRef={faqRef} />
    </div>
  );
}
