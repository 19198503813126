import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UpgradePrompt from "./UpgradePrompt";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import LockIcon from "@mui/icons-material/Lock";
import { useSelector } from "react-redux";

const features = [
  {
    title: "AI Dating Profile Generator",
    color: "#E91E63",
    route: "/ai-dating-profile-generator",
    description:
      "Just upload 10-20 good quality selfies or pictures, and our advanced AI will transform them into unique, realistic images for your dating profile to get more matches.",
    isHot: true,
  },
  {
    title: "Generate Ice Breakers",
    color: "#FF4081",
    route: "/ice-breaker",
    description:
      "Stuck after a match? Don't know what to write? Just add a screenshot of your match's profile and get awesome ice breakers.",
  },
  // {
  //   title: "Rate Your Profile",
  //   color: "#3F51B5",
  //   route: "/profile-rater",
  //   description:
  //     "Get AI-powered insights on your dating profile photos to maximize your chances of success.",
  // },
  {
    title: "Dating Bio Generator",
    color: "#009688",
    route: "/dating-bio-generator",
    description:
      "Not sure how to write a dating bio? Just provide a couple of simple answers, and our advanced AI will craft a standout bio for you.",
  },
  {
    title: "Reply Enhancer",
    color: "#FFC107",
    route: "/message-reply-enhancer",
    description:
      "Not sure if your reply should be funny, witty, or playful? Just write the text or upload a screenshot of the message, and our advanced AI will enhance your reply.",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const { isPending, data } = useSelector((state) => state.user);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 8, mb: 6, textAlign: "center" }}>
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "#FF4081",
            fontSize: { xs: "3rem", md: "4rem" },
          }}
        >
          Welcome to RizzShot
        </Typography>
        <Typography
          variant="h5"
          color="text.secondary"
          paragraph
          sx={{ maxWidth: "800px", margin: "0 auto" }}
        >
          Elevate your dating game with AI-powered tools and insights
        </Typography>
      </Box>

      {isPending ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <UpgradePrompt userData={data} />

          <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
            {features.map((item, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card
                  elevation={3}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "16px",
                    overflow: "hidden",
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0 12px 20px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, p: 4, position: "relative" }}>
                    {item.isHot && (
                      <Chip
                        icon={<LocalFireDepartmentIcon />}
                        label="Hot & Exclusive"
                        color="error"
                        sx={{ position: "absolute", top: 16, right: 16 }}
                      />
                    )}
                    <Typography
                      variant="h4"
                      component="h2"
                      gutterBottom
                      sx={{ color: item.color, fontWeight: "bold" }}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {item.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 4, pt: 0 }}>
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth
                      onClick={() => navigate(item.route)}
                      sx={{
                        borderRadius: "8px",
                        py: 1.5,
                        bgcolor: item.color,
                        "&:hover": {
                          bgcolor: item.color,
                          opacity: 0.9,
                          transform: "scale(1.05)",
                          transition: "transform 0.2s ease-in-out",
                        },
                      }}
                      startIcon={
                        !data || data.subscriptionStatus !== "active" ? (
                          <LockIcon />
                        ) : null
                      }
                    >
                      {index === 0
                        ? "Try Now"
                        : index % 2 === 0
                        ? "Start Generating"
                        : "Get Started"}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default Home;
