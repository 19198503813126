import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getIsOnboarded, logOut } from "../app/slices/user";
import Logo from "../assets/logo.png";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { onboarded } = useSelector(getIsOnboarded);
  const { data, isAuthenticated } = useSelector((state) => state.user);

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/");
  };

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const menuItems = onboarded
    ? [
        { label: "Profile Rater", path: "/dating-profile-rater" },
        { label: "Ice Breaker", path: "/ice-breaker" },
        { label: "Dating Bio Generator", path: "/dating-bio-generator" },
        {
          label: "AI Dating Profile Generator",
          path: "/ai-dating-profile-generator",
        },
        { label: "Reply Enhancer", path: "/message-reply-enhancer" },
      ]
    : [{ label: "Home", path: "/" }];

  const renderMenuItems = (isDrawer = false) =>
    menuItems.map(({ label, path, onClick }) => (
      <Button
        key={label}
        component={path ? Link : undefined}
        to={path || undefined}
        onClick={onClick}
        sx={{
          textTransform: "none",
          color: "text.primary",
          width: isDrawer ? "100%" : "auto",
          display: isDrawer ? "block" : "inline-block",
          padding: isDrawer ? "10px 0" : "auto",
        }}
      >
        {label}
      </Button>
    ));

  return (
    <AppBar position="static" sx={{ backgroundColor: "white", boxShadow: 1 }}>
      <Toolbar>
        {/* Logo */}
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, color: "black", textDecoration: "none" }}
        >
          <div className="flex items-center gap-x-1">
            <img src={Logo} className="h-6" alt="Logo" /> RizzShot
          </div>
        </Typography>

        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setIsDrawerOpen(true)}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <Box
                sx={{ width: 250, padding: "16px" }}
                onClick={() => setIsDrawerOpen(false)}
              >
                {renderMenuItems(true)}
                {isAuthenticated && (
                  <>
                    <Button
                      onClick={handleProfileClick}
                      sx={{
                        textTransform: "none",
                        color: "text.primary",
                        width: "100%",
                      }}
                    >
                      Profile
                    </Button>
                    <Button
                      onClick={handleLogout}
                      sx={{
                        textTransform: "none",
                        color: "text.primary",
                        width: "100%",
                      }}
                    >
                      Logout
                    </Button>
                  </>
                )}
              </Box>
            </Drawer>
          </>
        ) : (
          <>
            {renderMenuItems()}
            {isAuthenticated && (
              <>
                <Button
                  onClick={handleProfileClick}
                  sx={{ textTransform: "none", color: "text.primary" }}
                >
                  Profile
                </Button>
                <Button
                  onClick={handleLogout}
                  sx={{ textTransform: "none", color: "text.primary" }}
                >
                  Logout
                </Button>
              </>
            )}
          </>
        )}
      </Toolbar>

      <Modal
        open={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
        aria-labelledby="profile-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" id="profile-modal-title">
            User Profile
          </Typography>
          {data ? (
            <>
              <Typography>Name: {data.name}</Typography>
              <Typography>Email: {data.email}</Typography>
              <Typography>Subscription: {data.subscriptionStatus}</Typography>
              <Typography>Plan: {data.subscriptionPlan}</Typography>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Credits
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary="Model Training"
                    secondary={data.credits.modelTraining}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Image Generation"
                    secondary={data.credits.imageGeneration}
                  />
                </ListItem>
              </List>

              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Usage
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary="Dating Bio Generator"
                    secondary={data.usage.datingBioGenerator}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Ice Breaker"
                    secondary={data.usage.iceBreaker}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Reply Enhancer"
                    secondary={data.usage.replyEnhancer}
                  />
                </ListItem>
              </List>
            </>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </Modal>
    </AppBar>
  );
};

export default Navbar;
