import { useState } from "react";
import { useDispatch } from "react-redux";
import { Tab } from "@headlessui/react";
import { asyncGetUser } from "../../app/slices/user";
import { styleCategories, GENDER } from "../../data/styleCategories";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StyleSelection() {
  const dispatch = useDispatch();
  const [selectedStyles, setSelectedStyles] = useState(new Set());
  const [activeGender, setActiveGender] = useState(GENDER.MAN);
  const [isLoading, setIsLoading] = useState(false);

  const handleStyleSelect = (category) => {
    setSelectedStyles((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(category)) {
        if (newSet.size > 7) {
          newSet.delete(category);
        } else if (newSet.size === 7) {
          newSet.delete(category);
        }
      } else {
        if (newSet.size >= 7) {
          newSet.add(category);
        } else if (newSet.size < 7) {
          newSet.add(category);
        }
      }
      return newSet;
    });
  };

  const handleContinue = async () => {
    if (isLoading) return;
    if (selectedStyles.size >= 7) {
      setIsLoading(true);
      const authToken = localStorage.getItem("token");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/onboarding/style-selection`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              styles: Array.from(selectedStyles),
              gender: activeGender,
            }),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "Something went wrong");
        }

        if (data?.success) {
          await dispatch(asyncGetUser({ authToken }));
        }
      } catch (error) {
        console.error("Style selection error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-orange-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12 max-w-3xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Design Your Perfect Dating Profile
          </h1>
          <p className="text-xl text-gray-600 mb-6">
            Choose the photo styles that best represent you. Our AI will create
            stunning, authentic photos that showcase your personality and
            attract the right matches.
          </p>
          <div className="bg-orange-100 rounded-lg p-4 text-orange-800 text-sm">
            <span className="font-semibold">Pro tip:</span> Select 5-7 different
            styles for a well-rounded profile. This gives potential matches a
            complete picture of who you are without overwhelming them.
          </div>
        </div>

        <div className="max-w-md mx-auto mb-8">
          <div className="flex items-center justify-between text-sm text-gray-600 mb-2">
            <span>Styles selected</span>
            <span className="font-medium">
              {selectedStyles.size < 7 ? `${selectedStyles.size}/7 required styles` : `${selectedStyles.size} styles selected`}
            </span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full">
            <div
              className={classNames(
                "h-2 rounded-full transition-all duration-300",
                selectedStyles.size >= 7 ? "bg-green-600" : "bg-orange-600"
              )}
              style={{
                width: `${Math.min((selectedStyles.size / 7) * 100, 100)}%`,
              }}
            />
          </div>
          <div className="flex justify-between text-xs text-gray-500 mt-1">
            <span>Minimum (5)</span>
            <span>Ideal (7)</span>
          </div>
        </div>

        <Tab.Group
          onChange={(index) => {
            const newGender = index === 0 ? GENDER.MAN : GENDER.WOMAN;
            setActiveGender(newGender);
            setSelectedStyles(new Set());
          }}
        >
          <Tab.List className="flex space-x-1 rounded-xl bg-orange-100 p-1 max-w-md mx-auto mb-8">
            {["Male Photos", "Female Photos"].map((category, idx) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-3 text-sm font-medium leading-5 transition-all duration-200",
                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-orange-400",
                    selected
                      ? "bg-white text-orange-700 shadow-md"
                      : "text-orange-600 hover:bg-white/[0.12] hover:text-orange-700"
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            {[GENDER.MAN, GENDER.WOMAN].map((genderType) => (
              <Tab.Panel
                key={genderType}
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              >
                {Object.entries(styleCategories[genderType]).map(
                  ([key, category]) => (
                    <div
                      key={key}
                      className={classNames(
                        "group relative rounded-xl overflow-hidden cursor-pointer transition-all duration-300",
                        "border-2 hover:shadow-xl transform hover:-translate-y-1",
                        selectedStyles.has(key)
                          ? "border-orange-600 shadow-lg"
                          : "border-gray-200 hover:border-orange-300"
                      )}
                      onClick={() => handleStyleSelect(key)}
                    >
                      <div className="aspect-w-16 aspect-h-9">
                        <img
                          src={category.image}
                          alt={category.title}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-6 bg-white">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">
                          {category.title}
                        </h3>
                        <p className="text-sm text-gray-600">
                          {category.description}
                        </p>
                      </div>
                      {selectedStyles.has(key) && (
                        <div className="absolute top-4 right-4 bg-orange-600 text-white rounded-full p-2.5 shadow-lg">
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )
                )}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>

        <div className="mt-12 flex flex-col items-center">
          <button
            onClick={handleContinue}
            disabled={selectedStyles.size < 7}
            className={classNames(
              "px-12 py-4 rounded-xl font-medium text-lg transition-all duration-300",
              "transform hover:scale-105 focus:outline-none focus:ring-4",
              selectedStyles.size >= 7
                ? "bg-orange-600 hover:bg-orange-700 text-white shadow-lg hover:shadow-xl"
                : "bg-gray-300 cursor-not-allowed text-gray-500"
            )}
          >
            Continue to Pricing
          </button>
          <p className="mt-4 text-sm text-gray-500">
            {selectedStyles.size < 7
              ? `Select ${7 - selectedStyles.size} more style${
                  selectedStyles.size === 6 ? "" : "s"
                } to continue`
              : selectedStyles.size === 7
              ? "Perfect! You've selected 7 styles. You can now add more if you'd like!"
              : `You've selected ${selectedStyles.size} styles. Feel free to add more or proceed!`}
          </p>
        </div>
      </div>
    </div>
  );
}
