import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { asyncGetUser, logOut } from "../app/slices/user";

const AuthCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      dispatch(asyncGetUser({ userToken: token }));
      localStorage.setItem("token", token);
      navigate("/");
    } else {
      dispatch(logOut());
    }
  }, [dispatch, location, navigate]);

  return <div>Processing authentication...</div>;
};

export default AuthCallback;
