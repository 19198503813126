import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Container,
  Grid,
  TextField,
  CircularProgress,
  Alert,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import UpgradePrompt from "./UpgradePrompt";
import { setRemainingToken } from "../app/slices/user";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import tinder from "../assets/tinder-icon.png";
import bumble from "../assets/bumble-icon.png";
import hinge from "../assets/hinge-icon.png";
import okcupid from "../assets/okcupid-icon.png";
import badoo from "../assets/badoo-icon.png";

const AppIcon = styled("img")({
  width: 80,
  height: 80,
  cursor: "pointer",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const datingApps = [
  { name: "Tinder", icon: tinder },
  { name: "Bumble", icon: bumble },
  { name: "Hinge", icon: hinge },
  { name: "OkCupid", icon: okcupid },
  { name: "Badoo", icon: badoo },
];

const appQuestions = {
  Tinder: [
    {
      id: "unexpectedTruth",
      label: "What’s something about yourself that’s unexpected but true?",
    },
    {
      id: "adventure",
      label: "Describe a perfect spontaneous adventure you’d take.",
    },
    { id: "foodCombo", label: "What’s a weird food combo you secretly love?" },
    {
      id: "headline",
      label:
        "If you could write a headline for your life right now, what would it be?",
    },
  ],
  Bumble: [
    {
      id: "proudAchievement",
      label:
        "What’s an achievement that you're genuinely proud of but never boast about?",
    },
    {
      id: "hiddenLove",
      label:
        "What’s one thing you love doing that most people don’t know about?",
    },
    {
      id: "relationshipDynamic",
      label: "Describe your ideal relationship dynamic using three adjectives.",
    },
    {
      id: "kindness",
      label:
        "What’s your favorite random act of kindness you’ve done or experienced?",
    },
  ],
  Hinge: [
    {
      id: "endlessPassion",
      label: "What’s one passion of yours that you could talk about endlessly?",
    },
    {
      id: "bucketList",
      label:
        "What’s one thing on your bucket list that you’re determined to accomplish, and why?",
    },
    {
      id: "friendsDescribe",
      label:
        "How do your friends describe you when introducing you to someone new?",
    },
    {
      id: "wouldYouRather",
      label: "Would you rather be crazy rich or deeply in love? Why?",
    },
  ],
  OkCupid: [
    {
      id: "strongBelief",
      label:
        "What’s a belief or cause you’re passionate about, and why does it matter to you?",
    },
    {
      id: "unstartedDream",
      label:
        "What’s a dream you’ve always wanted to achieve but haven’t started yet?",
    },
    {
      id: "desiredQuestion",
      label: "What’s one question you wish more people would ask you, and why?",
    },
    { id: "unwind", label: "How do you like to unwind after a long day?" },
  ],
  Badoo: [
    {
      id: "weekendMustDo",
      label: "What’s one thing you absolutely must do on weekends?",
    },
    {
      id: "bestAdvice",
      label: "What’s the best piece of advice you’ve ever received?",
    },
    {
      id: "funFact",
      label:
        "What’s a fun fact about yourself that most people wouldn’t expect?",
    },
    {
      id: "perfectDate",
      label: "What’s your idea of a perfect first date, and why?",
    },
  ],
};

const BioCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  transition: 'all 0.2s ease',
  cursor: 'pointer',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const CopyButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  padding: theme.spacing(1, 2),
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

const DatingBioGenerator = () => {
  const dispatch = useDispatch();
  const [selectedApp, setSelectedApp] = useState(null);
  const [answers, setAnswers] = useState({});
  const [generatedBios, setGeneratedBios] = useState([]);
  const [selectedBioIndex, setSelectedBioIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { data } = useSelector((state) => state.user);

  const handleAppSelect = (app) => {
    setSelectedApp(app);
    setAnswers({});
    setGeneratedBios([]);
  };

  const handleInputChange = (questionId, value) => {
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleGenerateBio = async () => {
    if (!data || data.subscriptionStatus !== "active") {
      setError("Please subscribe to use this feature.");
      return;
    }

    if (data.usage?.datingBioGenerator <= 0) {
      setError(
        "You have used all your credits for this feature. Please upgrade your plan."
      );
      return;
    }

    setLoading(true);
    setError("");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/generate-dating-bio`,
        { answers, selectedApp: selectedApp.name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGeneratedBios(response.data.bio);
      setSelectedBioIndex(null);
      dispatch(
        setRemainingToken({
          numberOfToken: 1,
          creaditKey: "usage.datingBioGenerator",
        })
      );
    } catch (err) {
      setError("Failed to generate bio. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleBioSelect = (index) => {
    setSelectedBioIndex(index);
  };

  const handleBuyCredits = () => {
    // Implement logic to redirect to credit purchase page or open a modal
    console.log("Redirecting to buy credits");
  };

  return (
    <Container maxWidth="md">
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{ mt: 4, fontWeight: "bold", color: "primary.main" }}
      >
        Dating Bio Generator
      </Typography>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ mb: 3, color: "text.secondary" }}
      >
        Answer a few simple questions and our AI will craft the perfect dating bio tailored to your chosen dating app
      </Typography>

      {data && (
        <Box mb={3}>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              mb: 4,
              backgroundColor: "primary.light",
              color: "primary.contrastText",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Your Dating Bio Generator Credits:{" "}
              {data.usage?.datingBioGenerator || 0}
            </Typography>
          </Paper>
          {data.subscriptionStatus === "active" &&
            data.usage?.datingBioGenerator <= 0 && (
              <Alert
                severity="warning"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={handleBuyCredits}
                  >
                    Buy Credits
                  </Button>
                }
              >
                You're out of credits for this feature. Buy more to continue
                using the Dating Bio Generator.
              </Alert>
            )}
        </Box>
      )}

      {data && data.subscriptionStatus !== "active" && (
        <Box mb={3}>
          <UpgradePrompt userData={data} />
        </Box>
      )}

      {!selectedApp ? (
        <>
          <Typography variant="h5" align="center" gutterBottom>
            Select a Dating App
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {datingApps.map((app) => (
              <Grid item key={app.name}>
                <AppIcon
                  src={app.icon}
                  alt={app.name}
                  onClick={() => handleAppSelect(app)}
                />
                <Typography align="center">{app.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Typography variant="h5" align="center" gutterBottom>
            Generating Bio for {selectedApp.name}
          </Typography>
          {selectedApp &&
            appQuestions[selectedApp.name].map((question) => (
              <TextField
                key={question.id}
                fullWidth
                label={question.label}
                variant="outlined"
                margin="normal"
                onChange={(e) => handleInputChange(question.id, e.target.value)}
                value={answers[question.id] || ""}
              />
            ))}
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateBio}
            disabled={
              loading ||
              Object.keys(answers).length !==
                appQuestions[selectedApp.name].length ||
              !data ||
              data.subscriptionStatus !== "active" ||
              data?.usage?.datingBioGenerator <= 0
            }
            sx={{ mt: 2 }}
          >
            {loading ? "Generating..." : "Generate Bios"}
          </Button>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          {loading && <CircularProgress sx={{ mt: 2 }} />}
          {generatedBios.length > 0 && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                Your Generated Bios:
              </Typography>
              <Grid container spacing={3}>
                {generatedBios.map((bio, index) => (
                  <Grid item xs={12} key={index}>
                    <BioCard 
                      onClick={() => handleBioSelect(index)}
                      sx={{
                        border: selectedBioIndex === index ? '2px solid #ea580c' : '1px solid #ccc',
                        backgroundColor: selectedBioIndex === index ? '#fff7ed' : 'white',
                        padding: 3,
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center', 
                        mb: 2,
                        borderBottom: '1px solid #eee',
                        pb: 1
                      }}>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            color: selectedBioIndex === index ? '#ea580c' : 'text.primary',
                            fontWeight: 500 
                          }}
                        >
                          Bio Option {index + 1}
                        </Typography>
                        <CopyButton
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(bio.replace(/^Bio \d+:\s*/, '').trim());
                            alert('Bio copied to clipboard!');
                          }}
                          startIcon={<ContentCopyIcon />}
                        >
                          Copy Bio
                        </CopyButton>
                      </Box>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word',
                          fontSize: '1.1rem',
                          lineHeight: 1.6,
                          color: 'text.primary',
                          padding: '12px',
                          backgroundColor: '#f8f9fa',
                          borderRadius: '8px',
                        }}
                      >
                        {bio.replace(/^Bio \d+:\s*/, '').trim()}
                      </Typography>
                      {selectedBioIndex === index && (
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'flex-end',
                          mt: 2,
                          pt: 1,
                          borderTop: '1px solid #eee'
                        }}>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: 'success.main',
                              fontStyle: 'italic'
                            }}
                          >
                            ✓ Selected Bio
                          </Typography>
                        </Box>
                      )}
                    </BioCard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default DatingBioGenerator;
