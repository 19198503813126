import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: { models: [] },
  error: null,
};

export const asyncGetUserModels = createAsyncThunk(
  "models/getUserModels",
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      const token = localStorage?.getItem("token");
      if (!token || state?.user?.isAuthenticated !== true) {
        return initialState;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/engine/user-models`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const modelsSlice = createSlice({
  name: "models",
  initialState,
  reducers: {
    setModels: (state, action) => {
      state.data = { ...state.data, ...action.payload?.data };
      state.isLoading = false;
      state.error = null;
    },
    setModelsOnly: (state, action) => {
      state.data.models = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetUserModels.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncGetUserModels.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(asyncGetUserModels.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setModels, setModelsOnly } = modelsSlice.actions;

const modelsSelector = (state) => state.model;

export const modelSelectors = createSelector([modelsSelector], (state) => {
  const _models = state?.data?.models;
  if (Array.isArray(_models) && _models.length) {
    const models = _models.map((model) => ({
      ...model,
      url: model?.s3Url || model?.falUrl,
    }));
    return { ...state, data: { models } };
  } else {
    return initialState;
  }
});

export default modelsSlice.reducer;
