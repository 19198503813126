import { useState, useEffect, useMemo } from "react";

function useAppLoading(token, isFetched, delay = 500) {
  const initialLoadingState = useMemo(() => {
    if (token && !isFetched) {
      return true;
    } else if (token && isFetched) {
      return false;
    } else {
      return false;
    }
  }, [isFetched, token]);

  const [loading, setLoading] = useState(initialLoadingState);

  useEffect(() => {
    if (token && isFetched) {
      const delayTimeout = setTimeout(() => {
        setLoading(false);
      }, delay);

      return () => clearTimeout(delayTimeout);
    } else {
      setLoading(initialLoadingState);
    }
  }, [initialLoadingState, token, isFetched, delay]);

  return loading;
}

export default useAppLoading;
