import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import Slider from "react-slick";
import ArrowLeft from "../../assets/landing/icons/arrow-pointer-left.png";
import ArrowRight from "../../assets/landing/icons/arrow-pointer-right.png";
import { importAll } from "../../utils/importHelper";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const slideSet = [
  {
    id: "Code",
    name: "Code",
    original: [
      {
        name: "Code 1",
        image: require("../../assets/set-images/original/code-org1.jpg"),
      },
      {
        name: "Code 2",
        image: require("../../assets/set-images/original/code-org2.jpg"),
      },
      {
        name: "Code 3",
        image: require("../../assets/set-images/original/code-org3.jpg"),
      },
    ],
    generated: [
      {
        key: "1",
        name: "Code 1",
        image: require("../../assets/set-images/generated/code-gen1.png"),
      },
      {
        key: "2",
        name: "Code 2",
        image: require("../../assets/set-images/generated/code-gen2.png"),
      },
      {
        key: "3",
        name: "Code 3",
        image: require("../../assets/set-images/generated/code-gen3.png"),
      },
      {
        key: "4",
        name: "Code 4",
        image: require("../../assets/set-images/generated/code-gen4.png"),
      },
      {
        key: "5",
        name: "Code 5",
        image: require("../../assets/set-images/generated/code-gen5.png"),
      },
      {
        key: "6",
        name: "Code 6",
        image: require("../../assets/set-images/generated/code-gen6.png"),
      },
    ],
  },
  {
    id: "Vode",
    name: "Vode",
    original: [
      {
        name: "Vode 1",
        image: require("../../assets/set-images/original/code1-org1.JPG"),
      },
      {
        name: "Vode 2",
        image: require("../../assets/set-images/original/code2-org2.jpeg"),
      },
      {
        name: "Vode 3",
        image: require("../../assets/set-images/original/code3-org3.JPG"),
      },
    ],
    generated: [
      {
        key: "1",
        name: "Vode 1",
        image: require("../../assets/set-images/generated/code1-gen1.png"),
      },
      {
        key: "2",
        name: "Vode 2",
        image: require("../../assets/set-images/generated/code9-gen9.png"),
      },
      {
        key: "3",
        name: "Vode 3",
        image: require("../../assets/set-images/generated/code3-gen3.png"),
      },
      {
        key: "4",
        name: "Vode 4",
        image: require("../../assets/set-images/generated/code4-gen4.png"),
      },
      {
        key: "5",
        name: "Vode 5",
        image: require("../../assets/set-images/generated/code5-gen5.png"),
      },
      {
        key: "6",
        name: "Vode 6",
        image: require("../../assets/set-images/generated/code6-gen6.png"),
      },
    ],
  },
];

let _slides = [];

for (let i = 0; i < slideSet.length; i++) {
  const slide = slideSet[i];
  const _original = slide.original.map((item) => ({ id: slide.id, ...item }));
  _slides = [..._slides, ..._original];
}

const slides = _slides.map((item, index) => ({
  key: index.toString(),
  ...item,
}));

const slideStyle = {
  display: "block",
  textAlign: "center",
  background: "#EAEBEE",
  color: "white",
  borderRadius: "50%",
  zIndex: 1,
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        ...slideStyle,
        right: "-50px",
      }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        ...slideStyle,
        left: "-50px",
      }}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon />
    </div>
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const settings2 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const sampleImages = importAll(
  require.context("../../assets/landing/samples", false, /\.(png|jpe?g|svg)$/)
);

export default function Gallery({ galleryRef }) {
  const [autoPlay, setAutoPlay] = useState(true);
  const [generated, setGenerated] = useState(slideSet[0].generated);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleAfterChange = (index) => {
    const _slide = slides.find((_, _index) => _index === index);
    const _generated = slideSet.find((slide) => slide.id === _slide.id);
    setGenerated(_generated.generated);
  };

  const moreExamples = Object.keys(sampleImages)
    .slice(0, 28) // Limit to  images
    .map((imagePath, index) => ({
      id: `example-${index + 1}`,
      name: `Example ${index + 1}`,
      image: sampleImages[imagePath],
    }));

  return (
    <div className="max-w-screen-xl mx-auto px-4 mt-16" ref={galleryRef}>
      <div className="w-full flex justify-center items-center flex-col mb-8">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-5xl">
            Real Results from Real Users
          </h1>
        </div>
        <div className="w-full max-w-sm text-center px-4">
          <p className="font-normal text-base">
            See how our users are getting 10x more matches with their enhanced
            photos
          </p>
        </div>
      </div>

      <div onClick={() => setAutoPlay(false)}>
        <div className="flex items-center justify-center">
          <div className="h-32 w-36 flex max-[900px]:hidden">
            <img src={ArrowLeft} alt="" className="object-cover h-full" />
          </div>
          <div className="mb-5 max-w-[32rem] max-[450px]:w-36">
            <Slider
              {...settings}
              afterChange={handleAfterChange}
              autoplay={autoPlay}
              speed={1500}
              autoplaySpeed={3000}
              {...(isMobile && { arrows: false })}
            >
              {slides.map((item) => (
                <div key={item.key} className="px-2">
                  <div className="h-[250px] rounded-xl overflow-hidden">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="h-32 w-36 flex justify-end max-[900px]:hidden">
            <img src={ArrowRight} alt="" className="object-cover h-full" />
          </div>
        </div>
        <div>
          <Slider {...settings2} {...(isMobile && { arrows: false })}>
            {generated.map((item) => (
              <div key={item.key} className="px-2">
                <div className="h-[500px] rounded-3xl overflow-hidden">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Updated More Examples section */}
      <div className="mt-16">
        <div className="text-center mb-16">
          <h2 className="font-semibold text-4xl mb-4">Real Success Stories</h2>
          <p className="font-normal text-base">
            Join thousands of users who found their perfect match with RizzShot
            photos
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {moreExamples.map((example) => (
            <div
              key={example.id}
              className="aspect-square rounded-xl overflow-hidden"
            >
              <img
                src={example.image}
                alt={example.name}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
