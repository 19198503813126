import { useNavigate } from "react-router-dom";
import Tinder from "../../assets/landing/brands/tinder.png";
import Bumble from "../../assets/landing/brands/bumble.png";
import Baddo from "../../assets/landing/brands/baddo.png";
import Hinge from "../../assets/landing/brands/hinge.png";

import AfterImg from "../../assets/landing/gallery/person/after.png";
import BeforeImg from "../../assets/landing/gallery/person/before.png";
import HeroArrow from "../../assets/landing/icons/heroarrow.png";

const brands = [
  {
    title: "Tinder",
    img: Tinder,
  },
  {
    title: "Bumble",
    img: Bumble,
  },
  {
    title: "Baddo",
    img: Baddo,
  },
  {
    title: "Hinge",
    img: Hinge,
  },
];

export default function Hero({ galleryRef }) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 mt-16">
        <div className="w-full max-w-lg max-[400px]:mb-8">
          <h1 className="font-semibold text-5xl mb-4">
            On dating apps you only have 2 seconds to impress
          </h1>
          <p className="font-normal text-base text-gray-800 mb-4 leading-5">
            Trained on 100,000+ top-rated dating profiles, our AI knows exactly
            what gets matches. Say goodbye to bathroom selfies, and hello to
            photos that get 10X more matches
          </p>
          <div>
            <p className="font-normal text-base text-gray-800 mb-3">
              Successfully verified and tested on
            </p>
            <div className="flex items-center gap-x-2.5 mb-2.5">
              {brands.map((brand) => (
                <div key={brand.title} title={brand.title}>
                  <img
                    height="18px"
                    width="auto"
                    src={brand.img}
                    alt={brand.title}
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => navigate("/login")}
            className="bg-orange-600 font-normal text-base text-white rounded-3xl px-6 py-3 mt-5"
          >
            Get More Matches
          </button>
        </div>
        <div className="w-full max-w-md h-auto">
          <div
            onClick={() =>
              galleryRef?.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <div className="flex z-0">
              <div className="h-60 w-60 rounded-2xl overflow-hidden">
                <img
                  src={BeforeImg}
                  alt=""
                  className="object-cover h-full w-full"
                />
              </div>
              <div className="flex flex-col mt-4 ml-4 cursor-pointer">
                <p className="font-semibold text-sm mb-2">
                  See more in <span className="underline">gallery</span>
                </p>
                <div className="w-7">
                  <img src={HeroArrow} alt="" />
                </div>
              </div>
            </div>
            <div className="-mt-32 -ml-7 flex justify-end z-10">
              <div className="w-60 h-60 rounded-2xl overflow-hidden">
                <img src={AfterImg} alt="" className="object-cover w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
