import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const faqs = [
  {
    id: "0",
    question: "What is RizzShot AI?",
    answer:
      "RizzShot AI is an advanced AI-powered platform that helps you create stunning dating profile photos and enhance your online dating presence. We use cutting-edge AI technology to generate professional-quality photos that highlight your best features and attract more matches.",
  },
  {
    id: "1",
    question: "How does RizzShot AI work?",
    answer:
      "Simply upload a few selfies, and our AI will generate multiple professional-looking photos in various styles and settings. You'll also get access to our dating bio generator, ice breaker suggestions, and message enhancement tools to maximize your dating success.",
  },
  {
    id: "2",
    question: "How long does it take to get my AI-generated photos?",
    answer:
      "Depending on your package, you'll receive your photos in 1-2 hours. Premium users enjoy priority processing with delivery times under 1 hour. You'll be notified via email as soon as your photos are ready.",
  },
  {
    id: "3",
    question: "Are the photos really unique to me?",
    answer:
      "Absolutely! Each photo is uniquely generated based on your uploaded images. Our AI maintains your core features while enhancing your photos with professional lighting, backgrounds, and poses that best represent you.",
  },
  {
    id: "4",
    question: "What if I'm not satisfied with my photos?",
    answer:
      "We offer a money-back guarantee if you're not completely satisfied with your results. Our customer support team will work with you to either regenerate your photos or process a refund if needed.",
  },
  {
    id: "5",
    question: "Can I use these photos on any dating app?",
    answer:
      "Yes! Your AI-generated photos are perfect for all dating platforms including Tinder, Bumble, Hinge, OkCupid, and more. You own full rights to use these photos on any dating or social media platform.",
  },
  {
    id: "6",
    question: "How many different styles can I choose from?",
    answer:
      "Depending on your package, you can access 10-30 different styles, including professional headshots, casual outdoor scenes, travel photos, and lifestyle shots. Each style is designed to showcase different aspects of your personality.",
  },
  {
    id: "7",
    question: "What are credits and how do they work?",
    answer:
      "Credits are used for our various features. You can spend them on custom image generation, dating bio creation, ice breakers, and message enhancement. Each package comes with a specific number of credits that you can use across all our features.",
  },
  {
    id: "8",
    question: "Is my data and privacy protected?",
    answer:
      "We take your privacy seriously. Your photos and personal information are encrypted and securely stored. We never share your data with third parties, and all uploaded photos are automatically deleted from our servers after processing.",
  },
  {
    id: "9",
    question: "Can I purchase additional credits later?",
    answer:
      "Yes! You can purchase additional credits at any time through your dashboard. We offer various top-up packages to suit your needs, and unused credits never expire.",
  },
];

export default function Faq({ faqRef }) {
  const [activeId, setActiveId] = useState(null);

  const handleSetActive = (id) => {
    activeId === id ? setActiveId(null) : setActiveId(id);
  };

  return (
    <div className="max-w-[1100px] mx-auto mt-16 p-4" ref={faqRef}>
      <div className="w-full flex justify-center items-center flex-col mb-8">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-5xl">FAQ</h1>
        </div>
        <div className="w-full max-w-sm text-center px-4">
          <p className="font-normal text-base">
            Everything you need to know about RizzShot AI.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap w-full gap-2 md:gap-1 justify-between">
        {faqs.map((faq) => (
          <div
            onClick={() => handleSetActive(faq.id)}
            className="w-full md:w-[49%] bg-gray-50 p-4 rounded-md cursor-pointer transition-transform transform hover:scale-105"
            key={faq.id}
            aria-expanded={activeId === faq.id}
          >
            <div className="flex items-center justify-between">
              <div className="text-gray-800 text-sm font-medium" role="button">
                {faq.question}
              </div>
              <div>
                {activeId === faq.id ? (
                  <RemoveIcon style={{ fontSize: 18 }} />
                ) : (
                  <AddIcon style={{ fontSize: 18 }} />
                )}
              </div>
            </div>

            {activeId === faq.id && (
              <div onClick={(e) => e.stopPropagation()} className="cursor-text">
                <div className="border-b my-2" />
                <div className="text-gray-600 text-sm font-medium">
                  {faq.answer}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
