import EUImg from "../../assets/landing/eu.jpg"; // Add this import

export default function Trust() {
  return (
    <div className="max-w-screen-xl mx-auto p-4 mt-16">
      <div className="text-center mb-12">
        <div className="flex items-center justify-center gap-2 mb-3">
          <img src={EUImg} alt="" className="h-5" />
          <span className="text-gray-800 text-base">
            Made in Europe. Your Privacy is Our Priority.
          </span>
        </div>
        <h2 className="font-semibold text-4xl md:text-5xl text-gray-700">
          Fast. Secure. Effective.
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* First Card */}
        <div className="border border-gray-300 rounded-3xl p-8">
          <div className="flex items-center gap-2 mb-4">
            <div className="w-10 h-10 rounded-full bg-orange-600 flex items-center justify-center">
              <span className="text-white text-xl">⭐</span>
            </div>
          </div>
          <h3 className="font-semibold text-2xl mb-3">Match-Ready Guarantee</h3>
          <p className="font-normal text-sm text-gray-500">
            If you don't get profile-stopping photos that get more matches,
            we'll refund your entire purchase.
          </p>
        </div>

        {/* Second Card */}
        <div className="border border-gray-300 rounded-3xl p-8">
          <div className="flex items-center gap-2 mb-4">
            <div className="w-10 h-10 rounded-full bg-orange-600 flex items-center justify-center">
              <span className="text-white text-xl">💎</span>
            </div>
          </div>
          <h3 className="font-semibold text-2xl mb-3">No Recurring Charges</h3>
          <p className="font-normal text-sm text-gray-500">
            No subscriptions or recurring charges. You have full commercial
            rights and ownership of your photos to use them wherever you desire.
          </p>
        </div>

        {/* Third Card */}
        <div className="border border-gray-300 rounded-3xl p-8">
          <div className="flex items-center gap-2 mb-4">
            <div className="w-10 h-10 rounded-full bg-orange-600 flex items-center justify-center">
              <span className="text-white text-xl">🛡️</span>
            </div>
          </div>
          <h3 className="font-semibold text-2xl mb-3">
            Your Photos Will Never Be Sold
          </h3>
          <p className="font-normal text-sm text-gray-500">
            Your dating life is private, and we keep it that way. Built in
            Europe with strict privacy standards, your photos stay secure and
            are never shared or sold.
          </p>
        </div>
      </div>
    </div>
  );
}
