export default function Banner({ bannerRef }) {
  return (
    <div
      ref={bannerRef}
      className="w-full bg-gradient-to-r from-orange-50 to-orange-100 border-b border-orange-200 fixed z-10"
    >
      <div className="max-w-screen-xl mx-auto py-3 px-4">
        <div className="flex flex-wrap items-center justify-center gap-x-8 gap-y-2 text-sm">
          <div className="flex items-center gap-x-2 whitespace-nowrap">
            <span className="text-base">🇪🇺</span>
            <span className="font-medium text-gray-800">Made in the EU</span>
          </div>
          <span className="hidden md:inline text-orange-300">●</span>
          <div className="flex items-center gap-x-2 whitespace-nowrap">
            <span className="text-base">🔒</span>
            <span className="font-medium text-gray-800">
              We take your privacy seriously
            </span>
          </div>
          <span className="hidden md:inline text-orange-300">●</span>
          <div className="flex items-center gap-x-2 whitespace-nowrap">
            <span className="text-base">📸</span>
            <span className="font-medium text-gray-800">
              <span className="text-orange-600">5,000+</span> professional
              dating pictures created
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
