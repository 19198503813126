const StepIcon = ({ children }) => (
  <div className="inline-flex items-center justify-center w-10 h-10 mr-3 bg-orange-100 rounded-full">
    <span className="text-orange-600 text-xl">{children}</span>
  </div>
);

const Arrow = () => (
  <div className="inline-flex mx-4 md:mx-6">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19M19 12L12 5M19 12L12 19"
        stroke="#F97316"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default function Steps() {
  return (
    <div className="max-w-screen-xl mx-auto p-4 mt-16">
      <h1 className="text-3xl md:text-5xl font-semibold text-center mb-8 md:mb-12">
        Transform Your Dating Profile in 3 Simple Steps
      </h1>
      <div className="border-gray-300 border rounded-3xl p-6 md:p-8 bg-white shadow-sm hover:shadow-md transition-shadow duration-300">
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="flex flex-col md:flex-row items-center">
            <div className="flex items-center mb-4 md:mb-0">
              <StepIcon>📸</StepIcon>
              <span className="text-gray-800 font-medium text-center md:text-left">
                Upload 10-20 of your favorite photos or selfies
              </span>
            </div>

            <Arrow />

            <div className="flex items-center mb-4 md:mb-0">
              <StepIcon>⚙️</StepIcon>
              <span className="text-gray-800 font-medium text-center md:text-left">
                Our Advanced Dating AI will Start working
              </span>
            </div>

            <Arrow />

            <div className="flex items-center">
              <StepIcon>⬇️</StepIcon>
              <span className="text-gray-800 font-medium text-center md:text-left">
                Download your indistinguishable Dating Pictures
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
