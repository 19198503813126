import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Chip,
  Alert,
  Switch,
  Paper,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import UpgradePrompt from "./UpgradePrompt";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetUser, setRemainingToken } from "../app/slices/user";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import heic2any from "heic2any";
import ImageUpload from "./ImageUpload";

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: "16px",
  padding: theme.spacing(6),
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(4),
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const PreviewImage = styled("img")({
  maxWidth: "100%",
  maxHeight: "200px",
  width: "auto",
  height: "auto",
  borderRadius: "8px",
  objectFit: "contain",
});

const PreviewContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "16px",
  marginBottom: "16px",
});

const ReplyCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  transition: "all 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4],
  },
}));

const CopyButton = styled(Button)(({ theme }) => ({
  minWidth: "auto",
  padding: theme.spacing(1, 2),
}));

const MessageReplyEnhancer = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.user);
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const [enhancedReplies, setEnhancedReplies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [useImage, setUseImage] = useState(true);
  const [selectedReplyIndex, setSelectedReplyIndex] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [converting, setConverting] = useState(false);

  const messageTypes = [
    "Poetic",
    "Sarcastic",
    "Funny",
    "Witty",
    "Romantic",
    "Roast",
  ];

  const convertHeicToJpeg = async (file) => {
    const isHeic = file.type === "image/heic" || 
                   file.type === "image/heif" || 
                   file.name.toLowerCase().endsWith('.heic') || 
                   file.name.toLowerCase().endsWith('.heif');

    if (isHeic) {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8,
          multiple: false,
          maxWidth: 1920,
          maxHeight: 1920,
          convertionOptions: {
            quality: 0.8,
            compression: 'low',
            skipMetadata: true
          }
        });

        const optimizedFile = new File([convertedBlob], file.name.replace(/\.(heic|HEIF)$/i, '.jpg'), {
          type: 'image/jpeg',
          lastModified: new Date().getTime()
        });

        return optimizedFile;
      } catch (err) {
        console.error('HEIC conversion error:', err);
        throw new Error("Failed to process iPhone image");
      }
    }
    return file;
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      const file = acceptedFiles[0];
      if (!file) return;

      setConverting(true);
      const processedFile = await convertHeicToJpeg(file);
      
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
      const newPreviewUrl = URL.createObjectURL(processedFile);
      setPreviewUrl(newPreviewUrl);
      setSelectedFile(processedFile);
    } catch (error) {
      console.error("File processing error:", error);
      setError(error.message);
    } finally {
      setConverting(false);
    }
  }, [previewUrl]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "image/webp": [],
      "image/heic": [],
    },
    maxFiles: 1,
  });

  const uploadBoxStyle = useMemo(
    () => ({
      backgroundColor: isDragActive ? "rgba(0, 0, 0, 0.1)" : "transparent",
    }),
    [isDragActive]
  );

  const handleEnhanceReply = async () => {
    if (!message && !selectedFile) {
      setError("Please provide meaningful text or upload an image with visible text.");
      return;
    }

    if (!selectedType) {
      setError("Please select a reply type.");
      return;
    }

    setError("");
    setLoading(true);

    const formData = new FormData();
    
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    
    formData.append("message", message);
    formData.append("type", selectedType);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/enhance-reply`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success && response.data.data?.variations) {
        const replies = response.data.data.variations.map((v) => v.content);
        setEnhancedReplies(replies);
        setSelectedReplyIndex(null);

        dispatch(
          setRemainingToken({
            numberOfToken: 1,
            creaditKey: "usage.replyEnhancer",
          })
        );
        dispatch(asyncGetUser());
      } else {
        throw new Error(response.data.error || "Failed to enhance reply");
      }
    } catch (error) {
      console.error("Error details:", error);
      setError(
        error.response?.data?.error ||
        error.message ||
        "Failed to enhance reply. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpgrade = async (selectedPackage) => {
    // Implement logic to start Stripe checkout for the selected package
    console.log(`Upgrading to ${selectedPackage} package`);
    // You would typically call your API here to start the Stripe checkout process
  };

  const handleBuyCredits = async () => {
    // Implement logic to start Stripe checkout for buying credits
    console.log("Buying more credits");
    // You would typically call your API here to start the Stripe checkout process
  };

  const renderEnhancedReplies = () => {
    if (!Array.isArray(enhancedReplies) || enhancedReplies.length === 0)
      return null;

    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          Choose Your Perfect Reply:
        </Typography>
        <Grid container spacing={3}>
          {enhancedReplies.map((reply, index) => (
            <Grid item xs={12} key={index}>
              <ReplyCard
                onClick={() => setSelectedReplyIndex(index)}
                sx={{
                  border:
                    selectedReplyIndex === index
                      ? "2px solid #ea580c"
                      : "1px solid #ccc",
                  backgroundColor:
                    selectedReplyIndex === index ? "#fff7ed" : "white",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    borderBottom: "1px solid #eee",
                    pb: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color:
                        selectedReplyIndex === index
                          ? "#ea580c"
                          : "text.primary",
                      fontWeight: 500,
                    }}
                  >
                    Reply Option {index + 1}
                  </Typography>
                  <CopyButton
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(reply);
                      alert("Reply copied to clipboard!");
                    }}
                    startIcon={<ContentCopyIcon />}
                  >
                    Copy Reply
                  </CopyButton>
                </Box>
                <Typography
                  sx={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    fontSize: "1.1rem",
                    lineHeight: 1.6,
                    color: "text.primary",
                    padding: "12px",
                    backgroundColor: "#f8f9fa",
                    borderRadius: "8px",
                  }}
                >
                  {reply}
                </Typography>
                {selectedReplyIndex === index && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 2,
                      pt: 1,
                      borderTop: "1px solid #eee",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "success.main",
                        fontStyle: "italic",
                      }}
                    >
                      ✓ Selected Reply
                    </Typography>
                  </Box>
                )}
              </ReplyCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const renderPreview = () => {
    if (!selectedFile) return null;
    
    return (
      <PreviewContainer>
        <PreviewImage
          src={previewUrl}
          alt="Uploaded"
        />
      </PreviewContainer>
    );
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const needsUpgrade = data.subscriptionStatus !== "active";
  const needsCredits = data.credits?.otherFeatures === 0;

  if (needsUpgrade) {
    return <UpgradePrompt userData={data} onUpgrade={handleUpgrade} />;
  }

  if (needsCredits) {
    return (
      <div>
        <h2>You're out of credits for this feature</h2>
        <p>
          Purchase more credits to continue using the Message Reply Enhancer.
        </p>
        <button onClick={handleBuyCredits}>Buy Credits</button>
      </div>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{
          mt: 4,
          mb: 4,
          fontWeight: "bold",
          color: "primary.light",
        }}
      >
        Message Reply Enhancer
      </Typography>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ mb: 3, color: "text.secondary" }}
      >
        Write a message or upload a screenshot of a message and let our AI craft
        the perfect response in your chosen style - from witty comebacks to
        romantic replies
      </Typography>
      {data ? (
        <>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              mb: 4,
              backgroundColor: "primary.light",
              color: "primary.contrastText",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Your Message Reply Enhancer Credits:{" "}
              {data.usage?.replyEnhancer ?? 0}
            </Typography>
          </Paper>
          {data.subscriptionStatus !== "active" ||
          data.credits?.otherFeatures <= 0 ? (
            <UpgradePrompt
              userData={data}
              onUpgrade={handleUpgrade}
              onBuyCredits={handleBuyCredits}
            />
          ) : (
            <>
              <Typography variant="body1" align="center" gutterBottom>
                Upload a photo or enter text, then select the type of reply you
                want to send.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={useImage}
                      onChange={() => setUseImage(!useImage)}
                    />
                  }
                  label={useImage ? "Using Image" : "Using Text"}
                />
              </Box>
              {useImage ? (
                <ImageUpload 
                  setExtractedText={setMessage}
                  converting={converting}
                  setConverting={setConverting}
                  convertHeicToJpeg={convertHeicToJpeg}
                  creditType="replyEnhancer"
                />
              ) : (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter the message you received here"
                  sx={{ mt: 4, mb: 2 }}
                />
              )}
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Select reply type:
                </Typography>
                <Grid container spacing={1}>
                  {messageTypes.map((type) => (
                    <Grid item key={type}>
                      <Chip
                        label={type}
                        onClick={() => setSelectedType(type)}
                        color={selectedType === type ? "primary" : "default"}
                        variant={selectedType === type ? "filled" : "outlined"}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEnhanceReply}
                disabled={
                  loading ||
                  !message ||
                  message.trim().length < 10 ||
                  !selectedType
                }
                sx={{ mt: 2, display: "block", margin: "0 auto", px: 4, py: 1 }}
              >
                {loading ? "Enhancing..." : "Enhance Reply"}
              </Button>
              {loading && (
                <Box sx={{ mt: 4, textAlign: "center" }}>
                  <CircularProgress sx={{ mb: 2 }} />
                  <Typography variant="body1" color="textSecondary">
                    Crafting your perfect replies...
                  </Typography>
                </Box>
              )}
              {error && (
                <Alert 
                  severity="error" 
                  sx={{ 
                    mt: 2,
                    backgroundColor: 'rgba(211, 47, 47, 0.1)',
                    color: 'error.main'
                  }}
                >
                  {error}
                </Alert>
              )}
              {renderEnhancedReplies()}
            </>
          )}
        </>
      ) : (
        <Typography>Loading user data...</Typography>
      )}
    </Container>
  );
};

export default MessageReplyEnhancer;
