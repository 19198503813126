// man images
import ProfessionalSuccess from "../assets/onboarding/styleSelection/man/Professional-Success.png";
import ActiveAthletic from "../assets/onboarding/styleSelection/man/Active-Athletic.png";
import AdventureTravel from "../assets/onboarding/styleSelection/man/Adventure-Travel.png";
import BusinessPower from "../assets/onboarding/styleSelection/man/Business-Power.png";
import CasualAuthentic from "../assets/onboarding/styleSelection/man/Casual-Authentic.png";
import CreativeArtistic from "../assets/onboarding/styleSelection/man/Creative-Artistic.png";
import LifestyleSocial from "../assets/onboarding/styleSelection/man/Lifestyle-Social.png";
import LuxuryLifestyle from "../assets/onboarding/styleSelection/man/Luxury-Lifestyle.png";
// import Aspirational from "../assets/onboarding/styleSelection/man/Aspirational.png";
// import OutdoorElegance from "../assets/onboarding/styleSelection/man/Outdoor-Elegance.png";
// import SocialConnection from "../assets/onboarding/styleSelection/man/Social-Connection.png";
// import EverydayAuthentic from "../assets/onboarding/styleSelection/man/Everyday-Authentic.png";

// woman images
import WomanProfessionalSuccess from "../assets/onboarding/styleSelection/woman/women-Professional-Success.png";
import WomanActiveAthletic from "../assets/onboarding/styleSelection/woman/women-Active-Athletic.png";
import WomanAdventureTravel from "../assets/onboarding/styleSelection/woman/women-Adventure-Travel.png";
import WomanBusinessPower from "../assets/onboarding/styleSelection/woman/women-Business-Power.png";
import WomanCasualAuthentic from "../assets/onboarding/styleSelection/woman/women-Everyday-Authentic.png";
import WomanCreativeArtistic from "../assets/onboarding/styleSelection/woman/women-Creative-Artistic.png";
import WomanLifestyleSocial from "../assets/onboarding/styleSelection/woman/women-Lifestyle-Social.png";
import WomanLuxuryLifestyle from "../assets/onboarding/styleSelection/woman/women-Luxury-Lifestyle.png";

export const GENDER = {
  MAN: "man",
  WOMAN: "woman",
};

export const styleCategories = {
  [GENDER.MAN]: {
    "Professional/Success": {
      title: "Professional Success",
      description:
        "Command attention with powerful business shots that show your ambitious side",
      image: ProfessionalSuccess,
    },
    "Lifestyle/Social": {
      title: "Social Lifestyle",
      description:
        "Show your fun side with natural shots of you enjoying life with friends",
      image: LifestyleSocial,
    },
    "Active/Athletic": {
      title: "Active & Athletic",
      description:
        "Showcase your energetic side with dynamic sports and fitness shots",
      image: ActiveAthletic,
    },
    "Adventure/Travel": {
      title: "Adventure & Travel",
      description:
        "Capture your adventurous spirit with stunning travel and exploration photos",
      image: AdventureTravel,
    },
    "Casual/Authentic": {
      title: "Casual & Authentic",
      description:
        "Display your genuine self in natural, relaxed everyday moments",
      image: CasualAuthentic,
    },
    "Creative/Artistic": {
      title: "Creative & Artistic",
      description:
        "Highlight your creative passion with artistic and expressive shots",
      image: CreativeArtistic,
    },
    "Business/Power": {
      title: "Business & Power",
      description:
        "Exude confidence with shots that showcase your leadership and success",
      image: BusinessPower,
    },
    "Luxury Lifestyle": {
      title: "Luxury Lifestyle",
      description:
        "Impress with sophisticated shots in upscale settings and experiences",
      image: LuxuryLifestyle,
    },
  },
  [GENDER.WOMAN]: {
    "Professional/Success": {
      title: "Professional Success",
      description:
        "Command attention with powerful business shots that show your ambitious side",
      image: WomanProfessionalSuccess,
    },
    "Lifestyle/Social": {
      title: "Social Lifestyle",
      description:
        "Show your fun side with natural shots of you enjoying life with friends",
      image: WomanLifestyleSocial,
    },
    "Active/Athletic": {
      title: "Active & Athletic",
      description:
        "Showcase your energetic side with dynamic sports and fitness shots",
      image: WomanActiveAthletic,
    },
    "Adventure/Travel": {
      title: "Adventure & Travel",
      description:
        "Capture your adventurous spirit with stunning travel and exploration photos",
      image: WomanAdventureTravel,
    },
    "Casual/Authentic": {
      title: "Casual & Authentic",
      description:
        "Display your genuine self in natural, relaxed everyday moments",
      image: WomanCasualAuthentic,
    },
    "Creative/Artistic": {
      title: "Creative & Artistic",
      description:
        "Highlight your creative passion with artistic and expressive shots",
      image: WomanCreativeArtistic,
    },
    "Business/Power": {
      title: "Business & Power",
      description:
        "Exude confidence with shots that showcase your leadership and success",
      image: WomanBusinessPower,
    },
    "Luxury Lifestyle": {
      title: "Luxury Lifestyle",
      description:
        "Impress with sophisticated shots in upscale settings and experiences",
      image: WomanLuxuryLifestyle,
    },
  },
};
