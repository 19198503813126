import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useCallback, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { modelSelectors } from "../app/slices/model";
import idealPhoto1 from "../assets/ideal/high-definition.jpg";
import idealPhoto2 from "../assets/ideal/expressions.jpg";
import idealPhoto3 from "../assets/ideal/diverse-locations.jpg";
import idealPhoto4 from "../assets/ideal/angles.jpg";

import avoidPhoto1 from "../assets/avoid/covered-face.jpg";
import avoidPhoto2 from "../assets/avoid/blurry.jpg";
import avoidPhoto3 from "../assets/avoid/poorly-cropped.jpg";
import avoidPhoto4 from "../assets/avoid/sunglasses.jpg";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";

const MIN_UPLOAD_LIMIT = 10;
const MAX_UPLOAD_LIMIT = 50;

const IdealPhotoExamples = [
  {
    key: "0",
    img: idealPhoto1,
    // heading: "High Definition and Good Lighting:",
    // content:
    //   "Ensure your photos are in high resolution with clear and bright lighting. Please ensure your uploaded images are at least 1024x1024 pixels in quality.",
    heading: "High Quality Photos:",
    content:
      "Upload clear, bright photos or selfies (minimum 1024x1024 pixels).",
  },
  {
    key: "1",
    img: idealPhoto2,
    heading: "Various Facial Expressions:",
    content:
      "Showcase different emotions—smiling, serious, relaxed—to bring out different aspects of your personality. ",
  },
  {
    key: "2",
    img: idealPhoto3,
    heading: "Diverse Locations and Outfits:",
    content:
      "Use a range of backgrounds and wear different clothes to add variety to your pictures.Ensure you are at a reasonable distance from the camera; close-ups are not suitable.",
  },
  {
    key: "3",
    img: idealPhoto4,
    heading: "Variation of Backgrounds, Angles & Scenes:",
    content:
      "Mix up the angles, backgrounds, and scenes for an engaging gallery.",
  },
];

const PhotostoAvoid = [
  {
    key: "0",
    img: avoidPhoto1,
    heading: "Cutoff or Covered Faces:",
    content:
      "Your entire face should be visible without any cropping or obstructions.",
  },
  {
    key: "1",
    img: avoidPhoto2,
    heading: "Multiple Persons or Blurry Images:",
    content:
      "Only you can be in the frame - no group photos or pictures with other people. Photos should be sharp and clear.",
  },
  {
    key: "2",
    img: avoidPhoto3,
    heading: "Poorly Cropped or Features Not Visible:",
    content:
      "Avoid awkward cropping or anything that hides important facial features.",
  },
  {
    key: "3",
    img: avoidPhoto4,
    heading: "Sunglasses or Hats:",
    content:
      "Keep your eyes and face visible—no sunglasses, hats, or anything covering your features.",
  },
];

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme?.palette?.primary?.main || "#1976d2"}`,
  borderRadius: "16px",
  padding: theme?.spacing?.(6) || "48px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme?.spacing?.(4) || "32px",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: theme?.palette?.action?.hover || "#f5f5f5",
  },
}));

const PreviewContainer = styled(Box)({
  position: "relative",
  width: "100%",
  paddingTop: "100%",
  marginBottom: "16px",
  border: "2px solid #e0e0e0",
  borderRadius: "10px",
  overflow: "hidden",
});

const PreviewImage = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "8px",
});

const DeleteButton = styled(IconButton)({
  position: "absolute",
  top: 5,
  right: 5,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
});

const ModelTraining = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState(null);
  const [progress, setProgress] = useState(0);
  const [modelName, setModelName] = useState("");
  const [gender, setGender] = useState("");
  const _modelSelectors = useSelector(modelSelectors);
  const availableModels = _modelSelectors.data.models;
  const { data } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const [converting, setConverting] = useState(false);
  const fileInputRef = useRef(null);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      handleFiles(e.dataTransfer.files);
    }
  }, []);

  const handleChange = useCallback((e) => {
    if (e.target.files) {
      handleFiles(e.target.files);
    }
  }, []);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 3, // Only compress if file is over 3MB
      maxWidthOrHeight: 4096, // Keep very high resolution
      useWebWorker: true, // Better performance
      preserveExif: true, // Keep image metadata
      quality: 0.95, // Very high quality (95%)
    };

    try {
      // Skip compression for small files (under 3MB)
      if (file.size <= 3 * 1024 * 1024) {
        return file;
      }

      const compressedFile = await imageCompression(file, options);
      return new File([compressedFile], file.name, { type: file.type });
    } catch (error) {
      console.error("Compression error:", error);
      return file; // Return original file if compression fails
    }
  };

  const handleFiles = async (files) => {
    setConverting(true);
    try {
      const processedFiles = [];
      for (const file of files) {
        // First handle HEIC if needed
        const isHeic =
          file.type === "image/heic" ||
          file.type === "image/heif" ||
          file.name.toLowerCase().endsWith(".heic") ||
          file.name.toLowerCase().endsWith(".heif");

        let processedFile = file;
        if (isHeic) {
          // Convert HEIC to JPEG first
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 1,
            multiple: false,
          });
          processedFile = new File(
            [convertedBlob],
            file.name.replace(/\.(heic|HEIF)$/i, ".jpg"),
            { type: "image/jpeg" }
          );
        }

        // Then compress if needed
        const compressedFile = await compressImage(processedFile);
        processedFiles.push(compressedFile);
      }

      setSelectedFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, ...processedFiles];
        if (updatedFiles.length > MAX_UPLOAD_LIMIT) {
          setError(`Maximum ${MAX_UPLOAD_LIMIT} images allowed`);
          return prevFiles;
        }
        return updatedFiles;
      });
    } catch (err) {
      setError("Failed to process some images. Please try again.");
    } finally {
      setConverting(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleDelete = useCallback((index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }, []);

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000; // 2 seconds

  const uploadAndProcess = async (retryCount = 0) => {
    if (!hasEnoughCredits()) {
      setError(
        "You do not have enough credits to train a model. Please purchase more credits or upgrade your subscription."
      );
      return;
    }

    if (selectedFiles.length < MIN_UPLOAD_LIMIT) {
      setError(`Please upload at least ${MIN_UPLOAD_LIMIT} images.`);
      return;
    }
    if (selectedFiles.length > MAX_UPLOAD_LIMIT) {
      setError(`Maximum ${MAX_UPLOAD_LIMIT} images allowed.`);
      return;
    }
    setProcessing(true);
    setError("");
    setStatus("Uploading and processing...");

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });
    formData.append("modelName", modelName);
    formData.append("gender", gender);

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/engine/upload-and-process`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
            setStatus(`Uploading: ${percentCompleted}%`);
          },
        }
      );

      setStatus(
        "Your AI photographer is now learning your style! We'll notify you when your dating photos are ready"
      );
      // setModelTrainingCredits((prevCredits) => prevCredits - 1);

      // Start polling for updates
      // pollTrainingStatus(response.data.requestId);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage = error.response.data.message;
        setError(errorMessage);
        setStatus("");
        setProcessing(false);
      } else if (
        error.response &&
        error.response.data.message === "Insufficient model training credits"
      ) {
        setError(
          "You do not have enough credits to train a model. Please purchase more credits or upgrade your subscription."
        );
        setStatus("");
        setProcessing(false);
      } else if (error.response?.status === 413) {
        setError(
          "The files are too large. Please try again with smaller images."
        );
        setStatus("");
        setProcessing(false);
      } else if (retryCount < MAX_RETRIES) {
        setStatus(
          `An error occurred. Retrying... (Attempt ${
            retryCount + 1
          }/${MAX_RETRIES})`
        );
        setTimeout(() => uploadAndProcess(retryCount + 1), RETRY_DELAY);
      } else {
        setError(
          error.response?.data?.message ||
            "An error occurred during model training. Please try again later."
        );
        setStatus("");
        setProcessing(false);
      }
    }
  };

  const renderImagePreviews = () => (
    <Grid container spacing={2}>
      {selectedFiles.map((file, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>
          <PreviewContainer>
            <PreviewImage
              src={URL.createObjectURL(file)}
              alt={`Preview ${index + 1}`}
            />
            <DeleteButton onClick={() => handleDelete(index)}>
              <DeleteIcon />
            </DeleteButton>
          </PreviewContainer>
        </Grid>
      ))}
    </Grid>
  );

  const renderGenderSelection = () => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        I am a:
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant={gender === "man" ? "contained" : "outlined"}
            onClick={() => setGender("man")}
          >
            Man
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant={gender === "woman" ? "contained" : "outlined"}
            onClick={() => setGender("woman")}
          >
            Woman
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  const renderPhotoExamples = () => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Photo Guidelines:
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        Upload {MIN_UPLOAD_LIMIT}-{MAX_UPLOAD_LIMIT} photos that follow these
        guidelines.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            ✅Ideal Photo Examples:
          </Typography>
          <div className="flex flex-col gap-y-3">
            {IdealPhotoExamples.map((item) => (
              <div className="flex gap-x-4 w-full" key={item.key}>
                <div className="flex items-centers gap-x-2 w-full">
                  <div className="w-24">
                    <img
                      className="rounded-md object-cover"
                      style={{
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      }}
                      src={item.img}
                      alt=""
                    />
                  </div>
                  <div className="w-full">
                    <strong className="text-base">{item.heading}</strong>{" "}
                    {item.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            🚫Photos to Avoid:
          </Typography>
          <div className="flex flex-col gap-y-3">
            {PhotostoAvoid.map((item) => (
              <div className="flex gap-x-4 w-full" key={item.key}>
                <div className="flex items-centers gap-x-2 w-full">
                  <div className="w-24">
                    <img
                      className="rounded-md object-cover"
                      style={{
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      }}
                      src={item.img}
                      alt=""
                    />
                  </div>
                  <div className="w-full">
                    <strong className="text-base">{item.heading}</strong>{" "}
                    {item.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </Box>
  );

  const renderAvailableModels = () => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Your Available Trained AI Models Based on Your Uploaded Photos:
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model Name</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availableModels.map((model, index) => (
              <TableRow key={index}>
                <TableCell>{model.name}</TableCell>
                <TableCell>
                  {new Date(model.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell>{model.status}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleGeneratePictures(model.name, model.requestId)
                    }
                    disabled={model.status.toLowerCase() !== "completed"}
                  >
                    Explore Your AI Photos
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const handleGeneratePictures = (modelName, requestId) => {
    navigate("/image-generation", {
      state: { selectedModel: modelName, requestId: requestId },
    });
  };

  const hasEnoughCredits = () => {
    return data?.credits?.modelTraining > 0;
  };

  const hasModelError = useMemo(() => {
    if (Array.isArray(availableModels)) {
      const existModel = availableModels.find(
        (model) => model?.name === modelName
      );
      return !!existModel;
    } else {
      return false;
    }
  }, [availableModels, modelName]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4, textAlign: "center" }}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{ fontWeight: "bold", color: "primary.main" }}
        >
          Turn Your Selfies into Professional Dating Photos
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ color: "text.secondary" }}>
          Upload your everyday photos and let AI transform them into stunning
          dating profile pictures
        </Typography>
      </Box>

      <Paper
        elevation={3}
        sx={{
          p: 4,
          mb: 4,
          backgroundColor: "primary.light",
          color: "primary.contrastText",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Model Training Credits: {data?.credits?.modelTraining || 0}
        </Typography>
        <Typography variant="body1">
          {hasEnoughCredits()
            ? "Each credit lets you create a personal AI model based on your uploaded photos that will generate amazing dating photos just for you."
            : "You don't have any model training credits. Please upgrade your subscription or purchase credits to continue."}
        </Typography>
      </Paper>

      {data && data.credits.modelTraining === 0 && (
        <Alert severity="warning">
          You don't have any model training credits. Please upgrade your
          subscription or purchase credits to continue.
        </Alert>
      )}

      {availableModels.length > 0 && renderAvailableModels()}

      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ color: "primary.main" }}>
          Train Your Custom AI Model
        </Typography>
        <Divider sx={{ mb: 4 }} />

        {renderGenderSelection()}
        {renderPhotoExamples()}

        <UploadBox onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
          <input
            ref={fileInputRef}
            accept="image/jpeg,image/jpg,image/png,image/webp,image/heic,image/heif"
            type="file"
            multiple
            onChange={handleChange}
            style={{ display: "none" }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              size="large"
            >
              Upload Your Photos
            </Button>
          </label>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Drag and drop your best {MIN_UPLOAD_LIMIT}-{MAX_UPLOAD_LIMIT} photos
            here or click to select files
          </Typography>
          {converting && (
            <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
              Analyzing images, please wait...
            </Typography>
          )}
        </UploadBox>

        {renderImagePreviews()}

        <Box sx={{ mt: 4 }}>
          <TextField
            fullWidth
            label="What's your Dating AI model name?"
            placeholder="My AI Photographer"
            variant="outlined"
            value={modelName}
            helperText={hasModelError ? "Model name already exist" : ""}
            error={hasModelError}
            onChange={(e) => setModelName(e.target.value)}
            sx={{ mb: 2 }}
          />
        </Box>

        <Tooltip
          title={
            !hasEnoughCredits()
              ? "You need more credits to create an AI model"
              : ""
          }
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={uploadAndProcess}
              disabled={
                processing ||
                selectedFiles.length === 0 ||
                !modelName ||
                hasModelError ||
                !gender ||
                !hasEnoughCredits()
              }
              sx={{ mt: 4, py: 2, fontSize: "1.2rem" }}
              fullWidth
            >
              {processing
                ? "Training Your AI Photographer..."
                : "Train My AI Photographer"}
            </Button>
          </span>
        </Tooltip>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {processing && (
          <Box sx={{ width: "100%", mt: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
              {status}
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default ModelTraining;
