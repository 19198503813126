import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useState } from "react";
import classNames from "../../utils/classNames";
import prices from "../../data/pricing";

const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const FormatFeature = ({ text }) => {
  if (text === "Money-back Guarantee") {
    return (
      <div className="flex items-center justify-center gap-2 mb-3">
        <span className="text-pink-600 text-lg">•</span>
        <span className="text-sm font-semibold text-pink-600">{text}</span>
      </div>
    );
  }

  const [title, value] = text.split(":");
  if (!value) return text;

  return (
    <div className="flex items-center w-full gap-y-3 px-4">
      <div className="text-pink-600 text-lg mr-1">•</div>
      <div className="text-sm text-left">
        <span className="text-gray-900 font-medium">{title}</span>
        <span className="text-gray-600 ml-1">:{value}</span>
      </div>
    </div>
  );
};

export default function Onboarding() {
  const [disabled, setDisabled] = useState(false);

  const handleClicktoBuy = async (priceId) => {
    if (disabled) return;
    setDisabled(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/create-checkout-session`,
        { priceKey: priceId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.success === true && stripe) {
        const result = await stripe.redirectToCheckout({
          sessionId: response.data.id,
        });
        if (result.error) {
          console.error(result.error.message);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto p-4 mt-16">
      <div className="w-full flex justify-center items-center flex-col mb-12">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-3xl md:text-5xl">
            Start Getting More Matches Tonight, Not Next Month
          </h1>
        </div>
        <div className="w-full max-w-sm text-center">
          <p className="font-normal text-base">
            Reimagine your online dating profile photos with RizzShot AI.
          </p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-6 w-full">
        {prices.map((item) => (
          <div key={item.key} className="flex-1">
            <div className="border border-gray-200 rounded-3xl p-6 lg:p-8 hover:shadow-lg transition-all duration-300">
              <div className="text-center">
                <div className="font-semibold text-xl md:text-2xl mb-2">
                  {item.name}
                </div>
                <div className="font-bold text-3xl md:text-4xl text-pink-600 mb-1">
                  ${item.price}
                  <span className="text-base font-normal text-gray-600 ml-1">
                    one-time
                  </span>
                </div>

                <div className="h-px bg-gray-200 my-6" />

                <div className="space-y-1">
                  {item.features.map((feature) => (
                    <FormatFeature key={feature.key} text={feature.content} />
                  ))}
                </div>

                <div className="mt-8">
                  <button
                    disabled={disabled}
                    onClick={() => handleClicktoBuy(item.priceId)}
                    className={classNames(
                      "w-full py-3 px-6 rounded-xl font-medium transition-colors duration-300",
                      "bg-pink-600 text-white hover:bg-pink-700",
                      disabled && "opacity-75 cursor-not-allowed"
                    )}
                  >
                    Get Started Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
