import { useState, useCallback, useRef, useEffect } from "react";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./layout/GHeader";
import Footer from "./layout/GFooter";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import logo from "../assets/landing/brands/logo512.png";
import EUImg from "../assets/landing/eu.jpg";
import heic2any from "heic2any";

const Container = styled("div")({
  maxWidth: "1200px",
  margin: "0 auto",
  padding: "40px 20px",
});

const ScoreCard = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "24px",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  marginBottom: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const PromoCard = styled("div")({
  backgroundColor: "#fff7ed",
  borderRadius: "16px",
  padding: "24px",
  margin: "24px 0",
  border: "1px solid #fed7aa",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "4px",
    height: "100%",
    background: "linear-gradient(to bottom, #ea580c, #fb923c)",
  },
});

const ScoreMetricCard = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "24px",
  boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "translateY(-2px)",
  },
});

const ScoreValue = styled("div")({
  display: "flex",
  alignItems: "baseline",
  gap: "4px",
  "& .number": {
    fontSize: "32px",
    fontWeight: "700",
    background: "linear-gradient(45deg, #ea580c 30%, #fb923c 90%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  "& .max": {
    fontSize: "16px",
    color: "#999",
  },
});

const formatAnalysisResults = (analysis, navigate) => {
  return (
    <div className="mt-12 max-w-3xl mx-auto">
      {/* Overall Score Card */}
      <ScoreCard>
        <div>
          <h2 className="text-3xl font-semibold mb-2">
            🎯 Match Success Probability
          </h2>
          <div className="text-sm text-gray-600 mb-2">
            Analyzed by AI trained on 100k+ successful profiles
          </div>
          {analysis.overallScore <= 6 ? (
            <div className="text-red-500 text-sm font-medium">
              Warning: Your current photos make it hard to get matches. With
              RizzShot.ai, you can get professional-quality photos that increase
              your match rate by 10x! 🚀
            </div>
          ) : analysis.overallScore <= 8 ? (
            <div className="text-yellow-600 text-sm font-medium">
              You're getting some matches, but you're not reaching your full
              potential. RizzShot.ai can help you get 10x more quality matches!
              ⭐
            </div>
          ) : (
            <div className="text-green-600 text-sm font-medium">
              Great photos! You're in the top 10% of profiles. You should be
              getting quality matches consistently! 🔥
            </div>
          )}
          <div className="flex items-center gap-1">
            {[...Array(5)].map((_, i) => (
              <StarIcon
                key={i}
                sx={{
                  color:
                    i < Math.round(analysis.overallScore / 2)
                      ? "#ea580c"
                      : "#E0E0E0",
                  fontSize: 28,
                }}
              />
            ))}
          </div>
        </div>
        <div className="w-32 flex items-center justify-center">
          <div className="flex items-center justify-center flex-col bg-orange-600 rounded-full w-[120px] h-[120px] border border-orange-600 text-white">
            <div className="text-xl font-semibold">
              {analysis.overallScore}/10
            </div>
            <div className="text-xs text-wrap text-center">
              {analysis.overallScore <= 6
                ? "Need RizzShot.ai!"
                : analysis.overallScore <= 8
                ? "Good, Can Be Better"
                : "Top Profile"}
            </div>
          </div>
        </div>
      </ScoreCard>

      {/* First photo analysis */}
      {analysis.analyses[0] && (
        <div key={0} className="bg-white rounded-xl p-8 shadow-lg mt-8">
          <div className="flex items-center justify-between mb-8">
            <h3 className="text-2xl font-bold text-gray-800">Photo 1</h3>
          </div>

          {/* Enhanced Scores Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            {[
              {
                label: "Overall",
                value: analysis.analyses[0].scores.overall || 0,
                icon: "🎯",
              },
              {
                label: "Lighting",
                value: analysis.analyses[0].scores.lighting || 0,
                icon: "💡",
              },
              {
                label: "Pose",
                value: analysis.analyses[0].scores.pose || 0,
                icon: "🎭",
              },
              {
                label: "Background",
                value: analysis.analyses[0].scores.background || 0,
                icon: "🖼️",
              },
            ].map((metric) => (
              <ScoreMetricCard key={metric.label}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#FFF5F8",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "24px",
                  }}
                >
                  {metric.icon}
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#666",
                      marginBottom: "4px",
                    }}
                  >
                    {metric.label}
                  </div>
                  <ScoreValue>
                    <span className="number">{metric.value}</span>
                    <span className="max">/10</span>
                  </ScoreValue>
                </div>
              </ScoreMetricCard>
            ))}
          </div>

          {/* Feedback Text */}
          <div className="text-gray-600 text-sm whitespace-pre-line bg-gray-50 rounded-xl p-6">
            {analysis.analyses[0].feedback}
          </div>
        </div>
      )}

      {/* Promo Card after first photo */}
      <PromoCard>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <div style={{ flex: 1 }}>
            <h3
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#ea580c",
                marginBottom: "8px",
              }}
            >
              Want Professional-Quality Dating Photos?
            </h3>
            <p
              style={{
                fontSize: "14px",
                color: "#666",
                marginBottom: "12px",
                lineHeight: 1.5,
              }}
            >
              Level up with RizzShotAI - Our premium AI photographer trained on
              10,0000+ successful dating profiles. Get personalized guidance for
              trendy, irresistible dating photos that attract more matches.
            </p>
            <Button
              style={{
                padding: "8px 16px",
                fontSize: "14px",
              }}
              onClick={() => navigate("/login")}
            >
              Try RizzShotAI Premium →
            </Button>
          </div>
          <div
            style={{
              width: "100px",
              height: "100px",
              backgroundColor: "#fff7ed",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              alt="RizzShotAI"
              style={{ width: "60px", height: "60px" }}
            />
          </div>
        </div>
      </PromoCard>

      {/* Remaining photo analyses */}
      {analysis.analyses.slice(1).map((item, index) => (
        <div key={index + 1} className="bg-white rounded-xl p-8 shadow-lg mt-8">
          <div className="flex items-center justify-between mb-8">
            <h3 className="text-2xl font-bold text-gray-800">
              Photo {index + 2}
            </h3>
          </div>

          {/* Enhanced Scores Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            {[
              { label: "Overall", value: item.scores.overall || 0, icon: "🎯" },
              {
                label: "Lighting",
                value: item.scores.lighting || 0,
                icon: "💡",
              },
              { label: "Pose", value: item.scores.pose || 0, icon: "🎭" },
              {
                label: "Background",
                value: item.scores.background || 0,
                icon: "🖼️",
              },
            ].map((metric) => (
              <ScoreMetricCard key={metric.label}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#FFF5F8",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "24px",
                  }}
                >
                  {metric.icon}
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#666",
                      marginBottom: "4px",
                    }}
                  >
                    {metric.label}
                  </div>
                  <ScoreValue>
                    <span className="number">{metric.value}</span>
                    <span className="max">/10</span>
                  </ScoreValue>
                </div>
              </ScoreMetricCard>
            ))}
          </div>

          {/* Feedback Text */}
          <div className="text-gray-600 text-sm whitespace-pre-line bg-gray-50 rounded-xl p-6">
            {item.feedback}
          </div>
        </div>
      ))}

      {/* Recommendations */}
      <div className="mt-12 bg-gradient-to-br from-orange-50 to-orange-100 rounded-xl p-8">
        <h3 className="text-xl font-semibold mb-4">Recommendations</h3>
        <ul className="space-y-3">
          {analysis.recommendedImprovements.map((rec, index) => (
            <li key={index} className="flex items-start gap-2">
              <span className="text-pink-500 text-xl">•</span>
              <span className="text-gray-700">{rec}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Title = styled("h1")({
  fontSize: "48px",
  textAlign: "center",
  color: "#333",
  marginBottom: "16px",
  fontWeight: "600",
});

const Subtitle = styled("p")({
  fontSize: "18px",
  textAlign: "center",
  color: "#666",
  marginBottom: "48px",
});

const UploadSection = styled("div")({
  display: "flex",
  gap: "32px",
  marginBottom: "48px",
  "@media (max-width: 768px)": {
    flexDirection: "column",
  },
});

const UploadBox = styled("div")({
  flex: "1",
  border: "2px dashed #fb923c",
  borderRadius: "16px",
  padding: "40px",
  backgroundColor: "#fff7ed",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "300px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#fed7aa",
  },
});

const PreviewSection = styled("div")({
  flex: "1",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "16px",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
});

const Button = styled("button")({
  backgroundColor: "#ea580c",
  color: "#fff",
  border: "none",
  borderRadius: "24px",
  padding: "12px 32px",
  fontSize: "16px",
  fontWeight: "500",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#c2410c",
  },
  "&:disabled": {
    backgroundColor: "#ccc",
    cursor: "not-allowed",
  },
});

const convertHeicToJpeg = async (file) => {
  // More comprehensive HEIC detection
  const isHeic = file.type === "image/heic" || 
                 file.type === "image/heif" || 
                 file.name.toLowerCase().endsWith('.heic') || 
                 file.name.toLowerCase().endsWith('.heif');

  if (isHeic) {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1,
        multiple: false
      });
      return new File([convertedBlob], file.name.replace(/\.(heic|HEIF)$/i, '.jpg'), {
        type: 'image/jpeg'
      });
    } catch (err) {
      console.error('HEIC conversion error:', err);
      throw new Error(`Failed to convert ${file.name}`);
    }
  }
  return file;
};

const ProfileAnalyzer = () => {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useSelector((state) => state.user);
  const [converting, setConverting] = useState(false);
  const [previewUrls, setPreviewUrls] = useState([]);
  const fileInputRef = useRef(null);
  const [isProcessingComplete, setIsProcessingComplete] = useState(true);

  useEffect(() => {
    return () => {
      previewUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, [previewUrls]);

  const handleDrop = useCallback(
    async (e) => {
      e.preventDefault();
      const files = Array.from(e.dataTransfer.files);
      if (files.length + selectedFiles.length > 10) {
        alert("Maximum 10 photos allowed");
        return;
      }
      await processFiles(files);
    },
    [selectedFiles.length]
  );

  const handleAnalyze = async () => {
    try {
      setAnalyzing(true);
      setError(null);

      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("photos", file);
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/profile-analyzer/analyze`,
        {
          method: "POST",
          headers: {},
          credentials: "include",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to analyze photos");
      }

      const data = await response.json();
      if (data.success) {
        setAnalysis(data.data);
      } else {
        throw new Error(data.message || "Analysis failed");
      }
    } catch (err) {
      setError(err.message);
      console.error("Analysis error:", err);
    } finally {
      setAnalyzing(false);
    }
  };

  const processFiles = async (files) => {
    setConverting(true);
    setIsProcessingComplete(false);
    const processedFiles = [];
    const newPreviewUrls = [];

    try {
      for (const file of files) {
        const processedFile = await convertHeicToJpeg(file);
        processedFiles.push(processedFile);
        
        const previewUrl = URL.createObjectURL(processedFile);
        newPreviewUrls.push(previewUrl);
        
        // Update state incrementally to show progress
        setSelectedFiles(prev => [...prev, processedFile]);
        setPreviewUrls(prev => [...prev, previewUrl]);
      }
    } catch (err) {
      setError("Failed to process some images. Please try again.");
      console.error(err);
    } finally {
      setConverting(false);
      setIsProcessingComplete(true);
    }
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length + selectedFiles.length > 10) {
      alert("Maximum 10 photos allowed");
      return;
    }
    await processFiles(files);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset input
    }
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    URL.revokeObjectURL(previewUrls[index]);
    setPreviewUrls(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      {!isAuthenticated && <Header />}
      <Container>
        <Title>AI Dating Profile Rater - Brutally Honest Feedback</Title>
        <Subtitle>
          Trained on 100,000+ successful dating profiles, our AI delivers
          brutally honest feedback that most humans won't tell you. We're here
          to boost your matches, even if the truth hurts. Ready for unfiltered
          analysis? Let's make your profile stand out! 🎯
          <div className="flex justify-center mt-4">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 max-w-3xl">
              <div className="bg-red-50 p-3 rounded-lg">
                <div className="text-red-600 font-semibold">1-5/10</div>
                <div className="text-sm text-gray-600">
                  Needs significant improvement. Hardly gets any matches.
                </div>
              </div>
              <div className="bg-yellow-50 p-3 rounded-lg">
                <div className="text-yellow-600 font-semibold">6-7/10</div>
                <div className="text-sm text-gray-600">
                  Gets few matches but could be better.
                </div>
              </div>
              <div className="bg-orange-50 p-3 rounded-lg">
                <div className="text-orange-600 font-semibold">8/10</div>
                <div className="text-sm text-gray-600">
                  Good profile. Consistent matches expected.
                </div>
              </div>

              {/* Centered flex container for 9/10 and 10/10 */}
              <div className="flex justify-center gap-3 col-span-2 md:col-span-3">
                <div className="bg-green-50 p-3 rounded-lg w-[calc(50%-0.375rem)] md:w-1/3">
                  <div className="text-green-600 font-semibold">9/10</div>
                  <div className="text-sm text-gray-600">
                    Excellent profile. High-quality matches likely.
                  </div>
                </div>
                <div className="bg-blue-50 p-3 rounded-lg w-[calc(50%-0.375rem)] md:w-1/3">
                  <div className="text-blue-600 font-semibold">10/10</div>
                  <div className="text-sm text-gray-600">
                    Perfect profile. Top-tier dating potential.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-2">
            <img src={EUImg} alt="" className="h-4" />
            <p className="font-normal text-xs ml-1">
              Made in the EU. We take your privacy seriously.
            </p>
          </div>
        </Subtitle>

        <UploadSection>
          <UploadBox onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
            <input
              ref={fileInputRef}
              type="file"
              id="file-upload"
              multiple
              accept="image/jpeg,image/jpg,image/png,image/webp,image/heic"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <CloudUploadIcon sx={{ fontSize: 48, color: "#ea580c", mb: 2 }} />
            <h2
              style={{
                fontSize: "24px",
                marginBottom: "16px",
                color: "#333",
                fontWeight: "500",
              }}
            >
              Get Brutally Honest Feedback
            </h2>
            <p
              style={{
                color: "#666",
                marginBottom: "24px",
              }}
            >
              Upload your photos and our AI will tell you exactly why you're not
              getting matches
            </p>
            <label htmlFor="file-upload">
              <Button as="span">SELECT PHOTOS</Button>
            </label>
          </UploadBox>

          <PreviewSection>
            <h3 style={{ fontSize: "20px", marginBottom: "16px", color: "#333" }}>
              Selected Photos ({selectedFiles.length}/10)
            </h3>
            {converting && (
              <div className="text-orange-600 text-sm mb-4">
                Reading images, please wait...
              </div>
            )}
            <div style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
              gap: "16px",
            }}>
              {selectedFiles.map((file, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    aspectRatio: "1",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={previewUrls[index]}
                    alt={`Preview ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <button
                    onClick={() => handleRemoveFile(index)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      backgroundColor: "rgba(255,255,255,0.8)",
                      border: "none",
                      borderRadius: "50%",
                      padding: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 20 }} />
                  </button>
                </div>
              ))}
            </div>
          </PreviewSection>
        </UploadSection>

        <div
          style={{
            textAlign: "center",
            marginTop: "48px",
          }}
        >
          <Button
            disabled={selectedFiles.length === 0 || analyzing || converting || !isProcessingComplete}
            onClick={handleAnalyze}
          >
            {analyzing ? "ANALYZING YOUR PHOTOS..." : 
             converting ? "PROCESSING IMAGES..." : 
             "GET FREE PHOTO ANALYSIS"}
          </Button>
        </div>

        {analysis && formatAnalysisResults(analysis, navigate)}

        {error && (
          <div
            style={{
              color: "red",
              textAlign: "center",
              marginTop: "24px",
            }}
          >
            {error}
          </div>
        )}
      </Container>
      {!isAuthenticated && <Footer />}
    </>
  );
};

export default ProfileAnalyzer;
