import StarImg from "../../assets/landing/icons/star.png";
import CrossImg from "../../assets/landing/icons/cross.png";
import VSImg from "../../assets/landing/versus.png";

const RizzShot = [
  {
    key: "1",
    content: "Upload any selfie (Yes, even bathroom ones) - 5 minutes",
  },
  {
    key: "2",
    content:
      "Our AI trains on 100,000+ successful profiles. Our AI knows exactly what works for you",
  },
  {
    key: "3",
    content: "Get 100+ dating-optimized variations of yourself (40 minutes)",
  },
  {
    key: "4",
    content: "Each photo designed to make potential matches",
  },
  {
    key: "5",
    content:
      "Pick your best winning pictures from 100+ different variations of indistinguishable images.",
  },
  {
    key: "6",
    content: "Start getting more matches tonight, not next month",
  },
];

const OldSchool = [
  {
    key: "1",
    content: "Hunt for a dating profile photographer (good luck!)",
  },
  {
    key: "2",
    content: "Call them, email them, wait weeks or month to book a session",
  },
  {
    key: "3",
    content: "Stress over what to wear and buy new clothes ($200+)",
  },
  {
    key: "4",
    content:
      "Trek to the photoshoot location and the awkward look from the crowd",
  },
  {
    key: "5",
    content: "Awkwardly pose and hope for the best",
  },
  {
    key: "6",
    content: "Wait another week for edited photos",
  },
  {
    key: "7",
    content: "Finally update your profile (after a month)",
  },
];

export default function Compare() {
  return (
    <div className="max-w-[900px] mx-auto mt-16">
      <div className="w-full flex justify-center items-center flex-col mb-8">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-5xl">
            Why RizzShot is the Best Dating Profile Photographer
          </h1>
        </div>
        <div className="w-full max-w-sm text-center px-4">
          <p className="font-normal text-base">
            Reimagine your online dating profile photos with RizzShot AI.
          </p>
        </div>
      </div>

      <div className="flex justify-between items-stretch max-[400px]:flex-col max-[400px]:gap-y-5">
        <div className="border-gray-300 border rounded-3xl flex flex-col relative">
          <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
            <span className="bg-green-100 text-green-800 text-sm font-semibold px-4 py-2 rounded-full whitespace-nowrap shadow-md">
              Starting with Only
              <span className="text-2xl ml-1 font-bold">$21</span>
            </span>
          </div>
          <div className="py-10 px-6 max-w-[440px] flex-grow mt-6">
            <div className="w-10 h-10 rounded-full bg-orange-600 flex items-center justify-center">
              <img src={StarImg} alt="" />
            </div>
            <div className="font-semibold text-2xl my-3">
              RizzShot (Results in 40 Minutes)
            </div>
            <div className="font-normal text-base">
              <div className="">
                {RizzShot.map((item) => (
                  <div key={item.key} className="flex mb-2">
                    <div className="mr-1">•</div>
                    {item.content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mx-2 w-10 self-center">
          <img src={VSImg} alt="" />
        </div>
        <div className="border-gray-300 border rounded-3xl flex flex-col relative">
          <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
            <span className="bg-red-100 text-red-800 text-sm font-semibold px-4 py-2 rounded-full whitespace-nowrap shadow-md">
              Starting with
              <span className="text-2xl ml-1 font-bold">$250</span>
            </span>
          </div>
          <div className="py-10 px-6 max-w-[440px] flex-grow mt-6">
            <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
              <img src={CrossImg} alt="" />
            </div>
            <div className="font-semibold text-2xl my-3">
              Old School (Weeks of Waiting)
            </div>
            <div className="font-normal text-base">
              <div className="">
                {OldSchool.map((item) => (
                  <div key={item.key} className="flex mb-2">
                    <div className="mr-1">•</div>
                    {item.content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
