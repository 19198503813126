import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedModel: "",
};

const micsSlice = createSlice({
  name: "mics",
  initialState,
  reducers: {
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
  },
});

export const miscActions = micsSlice.actions;

export const modelsSelector = (state) => state.misc;

export default micsSlice.reducer;
