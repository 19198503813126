import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./components/Home";
import IceBreaker from "./components/IceBreaker";
import Auth from "./components/Auth";
import AuthCallback from "./components/AuthCallback";
import PaymentSuccess from "./components/PaymentSuccess";
// import ProfileRater from "./components/ProfileRater";
import Navbar from "./components/Navbar";
import DatingBioGenerator from "./components/DatingBioGenerator";
// import LandingPage from "./components/LandingPage";
import Homepage from "./components/Homepage";
import MessageReplyEnhancer from "./components/MessageReplyEnhancer";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import ModelTraining from "./components/ModelTraining";
import ImageGeneration from "./components/ImageGeneration";
import Receiver from "./components/Receiver";
import { asyncGetUser, userSelector, getIsOnboarded } from "./app/slices/user";
import { asyncGetUserModels } from "./app/slices/model";
import { useDispatch, useSelector } from "react-redux";
import Affiliate from "./components/Affiliate";
import RefundPolicy from "./components/RefundPolicy";
import Head from "./components/Head";
import "react-toastify/dist/ReactToastify.css";
import ProfileAnalyzer from "./components/ProfileAnalyzer";
import StyleSelection from "./components/onboarding/StyleSelection";
import OnboardingFlow from "./components/onboarding/index";
import useAppLoading from "./hooks/useAppLoading";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF4081",
    },
    secondary: {
      main: "#3F51B5",
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const { onboarded } = useSelector(getIsOnboarded);
  const { isAuthenticated, isFetched } = useSelector(userSelector);
  const token = localStorage?.getItem("token");
  const appLoading = useAppLoading(token, isFetched, 200);

  useEffect(() => {
    if (!isAuthenticated && token) {
      dispatch(asyncGetUser({ userToken: token }));
    }
  }, [dispatch, isAuthenticated, token]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(asyncGetUserModels());
    }
  }, [dispatch, isAuthenticated]);

  if (appLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="animate-spin w-8 h-8 border-4 border-t-transparent border-text-blue-500 rounded-full"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Head />
        {isAuthenticated && <Navbar />}
        <Routes>
          <Route path="/dating-profile-rater" element={<ProfileAnalyzer />} />
          {isAuthenticated ? (
            <>
              {onboarded ? (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="/ice-breaker" element={<IceBreaker />} />
                  {/* <Route path="/profile-rater" element={<ProfileRater />} /> */}
                  <Route
                    path="/dating-bio-generator"
                    element={<DatingBioGenerator />}
                  />
                  <Route
                    path="/message-reply-enhancer"
                    element={<MessageReplyEnhancer />}
                  />
                  <Route
                    path="/ai-dating-profile-generator"
                    element={<ModelTraining />}
                  />
                  <Route
                    path="/image-generation"
                    element={<ImageGeneration />}
                  />
                  <Route path="/style-selection" element={<StyleSelection />} />
                </>
              ) : (
                <Route path="/" element={<OnboardingFlow />} />
              )}
            </>
          ) : (
            <>
              {/* <Route path="/" element={<LandingPage />} /> */}
              <Route path="/" element={<Homepage />} />
              <Route path="/login" element={<Auth />} />
              <Route path="/auth/callback" element={<AuthCallback />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
            </>
          )}
          {/* Update these two routes */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/affiliate" element={<Affiliate />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          {isFetched ? (
            <Route
              path="*"
              element={<Navigate to={isAuthenticated ? "/" : "/login"} />}
            />
          ) : null}
        </Routes>
        {isAuthenticated ? <Footer /> : null}
      </Router>
      <Receiver />
      <ToastContainer position="top-right" autoClose={5000} theme="light" />
    </ThemeProvider>
  );
};

export default App;
