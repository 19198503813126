import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import EUImg from "../../assets/landing/eu.jpg";

export default function Footer({ pricingRef, faqRef }) {
  return (
    <>
      <div className="border-b w-full my-3" />
      <div className="max-w-screen-xl flex flex-wrap items-center mx-auto p-4">
        <div className="w-64 max-[500px]:mb-5">
          <div className="font-semibold text-xl mb-1.5 flex items-center">
            <img src={Logo} className="h-6 mr-1" alt="" /> RizzShot
          </div>
          <p className="font-normal text-sm">
            World's First AI Dating Photographer
          </p>
        </div>
        <div className="flex ml-24 gap-x-20 gap-y-5 text-sm font-medium max-[500px]:flex-wrap max-[500px]:ml-0">
          <div>
            <div className="mb-3">
              <Link to="/dating-profile-rater">Dating Profile Rater</Link>
            </div>
            <div>
              <button
                onClick={() =>
                  pricingRef?.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Pricing
              </button>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <button
                onClick={() =>
                  faqRef?.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                FAQ
              </button>
            </div>
            <div className="mb-3">
              <Link to={"/privacy-policy"}>Privacy policy</Link>
            </div>
            <div className="mb-3">
              <Link to={"/terms-of-service"}>Terms of Service</Link>
            </div>
            <div className="mb-3">
              <Link to={"/refund-policy"}>Refund policy</Link>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <div className="font-medium">Contact</div>
            </div>
            <div>
              <a
                href="mailto:support@rizzshot.ai"
                className="text-gray-600 hover:text-gray-800"
              >
                support@rizzshot.ai
              </a>
            </div>
          </div>
        </div>
        <div className="border-b w-full mt-8"></div>
        <div className="flex items-center justify-between my-4 w-full">
          <div className="flex items-center justify-center">
            <img src={EUImg} alt="" className="h-4" />
            <p className="font-normal text-xs ml-1">
              Made in the EU. We take your privacy seriously.
            </p>
          </div>
          <div className="font-normal text-xs">RizzShot.ai</div>
        </div>
      </div>
    </>
  );
}
