import UserImg1 from "../../assets/testimonials/user1.jpg";
import UserImg2 from "../../assets/testimonials/user2.jpg";
import UserImg3 from "../../assets/testimonials/user3.jpg";
import { Link } from "react-router-dom";
import prices from "../../data/pricing";

const FormatFeature = ({ text }) => {
  if (text === "Money-back Guarantee") {
    return (
      <div className="flex items-center justify-center gap-2 mb-3">
        <span className="text-orange-600 text-lg">•</span>
        <span className="text-sm font-semibold text-orange-600">{text}</span>
      </div>
    );
  }

  const [title, value] = text.split(":");
  if (!value) return text;

  return (
    <div className="flex items-center w-full gap-y-3 px-4">
      <div className="text-orange-600 text-lg mr-1">•</div>
      <div className="text-sm text-left">
        <span className="text-gray-900 font-medium">{title}</span>
        <span className="text-gray-600 ml-1">:{value}</span>
      </div>
    </div>
  );
};

export default function Pricing({ pricingRef }) {
  return (
    <div ref={pricingRef} className="max-w-screen-xl mx-auto p-4 mt-16">
      <div className="w-full flex justify-center items-center flex-col mb-12">
        <div className="w-full max-w-xl text-center mb-3 px-4">
          <h1 className="font-semibold text-3xl md:text-5xl">
            Start Getting More Matches Tonight, Not Next Month
          </h1>
        </div>
        <div className="w-full max-w-6xl text-center">
          <p className="font-normal text-base mb-8">
            Reimagine your online dating profile photos with RizzShot AI.
          </p>

          {/* Horizontal testimonials layout */}
          <div className="flex flex-nowrap justify-center gap-6 mt-8 overflow-x-auto px-4">
            <div className="flex items-start gap-4 min-w-[300px] max-w-[350px]">
              <img 
                src={UserImg1} 
                alt="" 
                className="w-12 h-12 rounded-full border-2 border-orange-200 flex-shrink-0 object-cover" 
              />
              <div className="text-left space-y-2">
                <div className="flex">⭐⭐⭐⭐⭐</div>
                <p className="text-sm text-gray-500 italic leading-relaxed">
                  "My mum can't say which ones is AI generated and which one is real, Best Dating AI Photographer!"
                </p>
                <div className="flex items-center gap-2 pt-1">
                  <p className="text-sm font-medium">David M.</p>
                  <span className="text-[11px] bg-green-50 text-green-600 px-2 py-0.5 rounded-full border border-green-100">
                    Verified
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-start gap-4 min-w-[300px] max-w-[350px]">
              <img 
                src={UserImg2} 
                alt="" 
                className="w-12 h-12 rounded-full border-2 border-orange-200 flex-shrink-0 object-cover" 
              />
              <div className="text-left space-y-2">
                <div className="flex">⭐⭐⭐⭐⭐</div>
                <p className="text-sm text-gray-500 italic leading-relaxed">
                  "43 matches in first day! Never had this many quality matches before"
                </p>
                <div className="flex items-center gap-2 pt-1">
                  <p className="text-sm font-medium">James R.</p>
                  <span className="text-[11px] bg-green-50 text-green-600 px-2 py-0.5 rounded-full border border-green-100">
                    Verified
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-start gap-4 min-w-[300px] max-w-[350px]">
              <img 
                src={UserImg3} 
                alt="" 
                className="w-12 h-12 rounded-full border-2 border-orange-200 flex-shrink-0 object-cover" 
              />
              <div className="text-left space-y-2">
                <div className="flex">⭐⭐⭐⭐⭐</div>
                <p className="text-sm text-gray-500 italic leading-relaxed">
                  "From 2 matches a week to 20+ quality matches daily! This is a game changer 🔥"
                </p>
                <div className="flex items-center gap-2 pt-1">
                  <p className="text-sm font-medium">Michael T.</p>
                  <span className="text-[11px] bg-green-50 text-green-600 px-2 py-0.5 rounded-full border border-green-100">
                    Verified
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Metrics bar with improved spacing */}
          <div className="flex justify-center gap-12 mt-10 text-xs text-gray-600">
            <div className="flex flex-col items-center gap-1">
              <span className="font-semibold text-orange-600 text-lg">10X</span>
              <span>More Matches</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span className="font-semibold text-orange-600 text-lg">98%</span>
              <span>Success Rate</span>
            </div>
            <div className="flex flex-col items-center gap-1">
              <span className="font-semibold text-orange-600 text-lg">24h</span>
              <span>Results</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-6 w-full">
        {prices.map((item) => (
          <div key={item.key} className="flex-1">
            <div
              className={`border rounded-3xl p-6 lg:p-8 hover:shadow-lg transition-all duration-300 
              ${
                item.key === "standard"
                  ? "border-orange-600 shadow-md"
                  : "border-gray-200"
              }`}
            >
              <div className="text-center">
                <div className="font-semibold text-xl md:text-2xl mb-2">
                  {item.name}
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-gray-500 line-through text-lg mb-1">
                    ${parseInt(item.price)}.00
                  </div>
                  <div className="font-bold text-3xl md:text-4xl text-orange-600 mb-1">
                    ${parseInt(item.price * 0.7)}.00
                    <span className="text-base font-normal text-gray-600 ml-1">
                      one-time
                    </span>
                  </div>
                  <div className="bg-green-100 text-green-700 text-sm px-3 py-1 rounded-full font-medium">
                    Save 30% with code: FindLove24
                  </div>
                </div>

                <div className="h-px bg-gray-200 my-6" />

                <div className="space-y-1">
                  {item.features.map((feature) => (
                    <FormatFeature key={feature.key} text={feature.content} />
                  ))}
                </div>

                <div className="mt-8">
                  <Link to="/login">
                    <button
                      className={`w-full py-3 px-6 rounded-xl font-medium transition-colors duration-300
                      ${
                        item.key === "standard"
                          ? "bg-orange-600 text-white hover:bg-orange-700"
                          : "bg-gray-100 text-gray-900 hover:bg-gray-200"
                      }`}
                    >
                      Get Started Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
