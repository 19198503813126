import { useNavigate } from "react-router-dom";

export default function Try() {
  const navigate = useNavigate();
  return (
    <div className="px-4">
      <div className="mx-auto flex flex-col justify-center max-w-[900px] w-full h-auto md:h-[300px] bg-[#191A330D] rounded-3xl mt-12 md:mt-16 mb-12 md:mb-16 p-6 md:p-12">
        <div className="md:ml-24">
          <div className="w-full md:w-[435px] text-center md:text-left">
            <h2 className="font-semibold text-2xl md:text-4xl mb-4 md:mb-2">
              Get More Matches Today
            </h2>
            <p className="font-normal text-base text-gray-800 mb-4">
              Every day, singles are finding their perfect match with our
              photos. Your future partner could be swiping right now - make sure
              they see your best self. Don't let another day pass without
              maximizing your chances of finding true love.
            </p>
          </div>
          <div className="flex justify-center md:justify-start mt-4 md:mt-6">
            <button
              onClick={() => navigate("/login")}
              className="bg-orange-600 px-6 py-2.5 rounded-3xl font-normal text-sm text-white"
            >
              Try Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
