import React from "react";
import { Link } from "react-router-dom";

export default function RefundPolicy() {
  return (
    <div>
      <nav className="bg-white">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/home" className="flex items-center space-x-3 rtl:space-x-reverse">
            ❤️
            <span className="self-center text-2xl font-semibold whitespace-nowrap">
              RizzShot
            </span>
          </Link>
        </div>
      </nav>

      <div className="max-w-screen-xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8">Refund Policy</h1>
        <p className="text-gray-600 mb-4">Last updated: {new Date().toLocaleDateString()}</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Money-Back Guarantee</h2>
          <p className="text-gray-700 mb-4">
            RizzShot is committed to delivering high-quality AI-enhanced photos that meet your expectations. We offer a 7-day money-back guarantee on all purchases. If you are not satisfied with our service, you may request a full refund within 7 days of your purchase. To initiate the refund process, please submit your request with a brief explanation of your concerns.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Refund Eligibility Requirements</h2>
          <p className="text-gray-700 mb-4">
            To maintain the integrity of our service and ensure fair usage, the following conditions apply:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-700">
            <li className="mb-3">
              <span className="font-semibold">Service Usage Guidelines:</span> Refunds will not be issued in cases where our service has been used in violation of our terms, including but not limited to: uploading prohibited content, attempting to circumvent system limitations, or any other form of service misuse.
            </li>
            <li className="mb-3">
              <span className="font-semibold">Refund Limitation:</span> Customers are eligible for one refund per account. This policy ensures a fair evaluation period while maintaining the sustainability of our service. Subsequent purchases are considered final.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Technical Issues and Service Interruptions</h2>
          <p className="text-gray-700 mb-4">
            In the event of technical difficulties or service interruptions that significantly impact your ability to use our service, please contact our support team immediately. Such cases will be evaluated individually, and appropriate compensation, including potential refunds, will be determined based on the specific circumstances.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <p className="text-gray-700 mb-4">
            For refund requests or questions regarding our refund policy, please contact our support team at{" "}
            <a href="mailto:support@rizzshot.ai" className="text-orange-600 hover:text-orange-700">
              support@rizzshot.ai
            </a>
            . We aim to process all refund requests within 3-5 business days.
          </p>
        </section>
      </div>

      <div className="border-b w-full my-3" />
      <div className="max-w-screen-xl flex flex-wrap items-center mx-auto p-4">
        <div className="w-64">
          <div className="font-semibold text-xl mb-1.5">❤️ RizzShot</div>
          <p className="font-normal text-sm">
            Ranked #1 Indistinguishable AI Dating Photographer
          </p>
        </div>
        <div className="flex ml-24 gap-x-20 text-sm font-medium">
          <div>
            <div>
              <Link to="/pricing">Pricing</Link>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <Link to="/faq">FAQ</Link>
            </div>
            <div className="mb-3">
              <Link to={"/privacy-policy"}>Privacy policy</Link>
            </div>
            <div className="mb-3">
              <Link to={"/terms-of-service"}>Terms of Service</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}