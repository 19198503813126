import { io } from "socket.io-client";

let socket;

export const initializeSocket = (authToken) => {
  if (!socket && authToken) {
    socket = io(`${process.env.REACT_APP_API_URL}`, {
      path: "/messenger",
      auth: { token: authToken },
      transports: ["websocket"],
    });
  }
  return socket;
};

export const getSocket = () => socket;
