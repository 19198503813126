import React, { useState } from "react";
import { Container, Typography, Button, Box, Paper } from "@mui/material";
import { styled } from "@mui/system";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import ImageUpload from "./ImageUpload";
import GenerateIcebreaker from "./GenerateIcebreaker";
import UpgradePrompt from "./UpgradePrompt";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { asyncGetUser, setRemainingToken } from "../app/slices/user";
import heic2any from "heic2any";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  textAlign: "center",
  borderRadius: "16px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
}));

const IceBreaker = () => {
  const dispatch = useDispatch();
  const [extractedText, setExtractedText] = useState("");
  const [rizzLines, setRizzLines] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { data } = useSelector((state) => state.user);
  const [converting, setConverting] = useState(false);

  const handleGenerateClick = async () => {
    if (!data || data.subscriptionStatus !== "active") {
      setError("You need an active subscription to use this feature.");
      return;
    }

    setLoading(true);
    setError("");
    setRizzLines([]);
    try {
      if (!extractedText) {
        throw new Error("No text extracted from the image.");
      }
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/generate-icebreaker`,
        { extractedText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        setRemainingToken({ numberOfToken: 1, creaditKey: "usage.iceBreaker" })
      );
      if (Array.isArray(response.data.rizzLine)) {
        setRizzLines(response.data.rizzLine);
        dispatch(asyncGetUser()); // Fetch updated user data after successful generation
      } else {
        throw new Error("Invalid response format from server");
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.message ||
          "Failed to generate icebreakers. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const convertHeicToJpeg = async (file) => {
    const isHeic = file.type === "image/heic" || 
                   file.type === "image/heif" || 
                   file.name.toLowerCase().endsWith('.heic') || 
                   file.name.toLowerCase().endsWith('.heif');

    if (isHeic) {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8,
          multiple: false,
          maxWidth: 1920,
          maxHeight: 1920,
          convertionOptions: {
            quality: 0.8,
            compression: 'low',
            skipMetadata: true
          }
        });

        const optimizedFile = new File([convertedBlob], file.name.replace(/\.(heic|HEIF)$/i, '.jpg'), {
          type: 'image/jpeg',
          lastModified: new Date().getTime()
        });

        if (optimizedFile.size > 1024 * 1024) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const img = new Image();

          return new Promise((resolve, reject) => {
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              const maxDimension = 1920;

              if (width > height && width > maxDimension) {
                height = (height * maxDimension) / width;
                width = maxDimension;
              } else if (height > maxDimension) {
                width = (width * maxDimension) / height;
                height = maxDimension;
              }

              canvas.width = width;
              canvas.height = height;

              try {
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob(
                  (blob) => {
                    if (!blob) {
                      reject(new Error("No text detected in the image. Please upload a dating profile with visible text."));
                      return;
                    }
                    resolve(new File([blob], optimizedFile.name, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                    }));
                  },
                  'image/jpeg',
                  0.8
                );
              } catch (err) {
                reject(new Error("No text detected in the image. Please upload a dating profile with visible text."));
              }
            };

            img.onerror = () => {
              reject(new Error("No text detected in the image. Please upload a dating profile with visible text."));
            };

            img.src = URL.createObjectURL(optimizedFile);
          });
        }

        return optimizedFile;
      } catch (err) {
        console.error('HEIC conversion error:', err);
        throw new Error("No text detected in the image. Please upload a dating profile with visible text.");
      }
    }
    return file;
  };

  if (!data) {
    return <Typography>Loading user data...</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{ mt: 4, fontWeight: "bold", color: "primary.main" }}
      >
        Ice Breaker
      </Typography>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ mb: 3, color: "text.secondary" }}
      >
        Upload any dating profile and our AI will analyze it to craft the perfect ice breaker message for you
      </Typography>
      <StyledPaper>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mb: 4,
            backgroundColor: "primary.light",
            color: "primary.contrastText",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Your Ice Breaker Credits: {data.usage?.iceBreaker || 0}
          </Typography>
        </Paper>
        {data.subscriptionStatus === "active" ? (
          <>
            {converting && (
              <Typography 
                sx={{ 
                  color: '#ea580c', // Orange color like in ProfileAnalyzer
                  fontSize: '0.875rem', // text-sm equivalent
                  marginBottom: 2,
                  backgroundColor: 'rgba(234, 88, 12, 0.1)',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  display: 'inline-block'
                }}
              >
                Reading images, please wait...
              </Typography>
            )}
            <ImageUpload 
              setExtractedText={setExtractedText} 
              converting={converting}
              setConverting={setConverting}
              convertHeicToJpeg={convertHeicToJpeg}
            />
            {extractedText && (
              <Box sx={{ mt: 4 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<EmojiEmotionsIcon />}
                  onClick={handleGenerateClick}
                  disabled={loading}
                  sx={{ borderRadius: "25px", padding: "10px 30px" }}
                >
                  {loading ? "Generating..." : "Generate Rizz"}
                </Button>
              </Box>
            )}
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            {rizzLines && Array.isArray(rizzLines) && rizzLines.length > 0 && (
              <GenerateIcebreaker rizzLines={rizzLines} />
            )}
          </>
        ) : (
          <UpgradePrompt
            userData={data}
            onBuyCredits={() => console.log("Buy credits clicked")}
          />
        )}
      </StyledPaper>
    </Container>
  );
};

export default IceBreaker;
