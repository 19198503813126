import React from "react";
import { Typography, Button, Box, CircularProgress, Grid } from "@mui/material";

const UpgradePrompt = ({ userData, onUpgrade = () => null, onBuyCredits }) => {
  if (!userData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const packages = [
    {
      name: "Professional",
      price: "$39",
      features: [
        "100 headshots",
        "20 unique backgrounds",
        "20 unique clothing styles",
        "1 hour turnaround time",
      ],
    },
    {
      name: "Executive",
      price: "$65",
      features: ["200 headshots", "40 unique styles", "1 hour turnaround time"],
    },
  ];

  const needsUpgrade = userData.subscriptionStatus !== "active";
  const needsCredits =
    userData.credits.modelTraining === 0 ||
    userData.credits.imageGeneration === 0;

  if (!needsUpgrade && !needsCredits) {
    return null; // Don't render anything if user has active subscription and sufficient credits
  }

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#f5f5f5",
      }}
    >
      {needsUpgrade ? (
        <>
          <Typography variant="h5" gutterBottom>
            Upgrade to Rizz Pro
          </Typography>
          <Typography variant="body1" paragraph>
            Choose a package to unlock all features:
          </Typography>
          <Grid container spacing={3}>
            {packages.map((pkg, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    p: 2,
                    borderRadius: "4px",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {pkg.name}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    {pkg.price}
                  </Typography>
                  <ul>
                    {pkg.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onUpgrade(pkg.name.toLowerCase())}
                    sx={{ mt: 2 }}
                  >
                    Choose {pkg.name}
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ) : needsCredits ? (
        <>
          <Typography variant="h5" gutterBottom>
            Buy More Credits
          </Typography>
          <Typography variant="body1" paragraph>
            You're out of credits. Recharge your account to continue using Rizz
            Pro features.
          </Typography>
          <Box sx={{ border: "1px solid #ddd", p: 2, borderRadius: "4px" }}>
            <Typography variant="h6" gutterBottom>
              Credit Package
            </Typography>
            <Typography variant="h5" gutterBottom>
              $25
            </Typography>
            <ul>
              <li>1 model training credit</li>
              <li>100 image generation credits</li>
            </ul>
            <Button
              variant="contained"
              color="primary"
              onClick={onBuyCredits}
              sx={{ mt: 2 }}
            >
              Buy Credits
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default UpgradePrompt;
