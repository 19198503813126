import React, { useState, useCallback, useEffect } from "react";
import { Typography, Box, Button, CircularProgress, Grid } from "@mui/material";
import { styled } from "@mui/system";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import api from "../utils/api";
import UpgradePrompt from "./UpgradePrompt";

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: "16px",
  padding: theme.spacing(4),
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ImagePreview = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ImageUpload = ({ 
  setExtractedText,
  converting,
  setConverting,
  convertHeicToJpeg,
  creditType = "iceBreaker"
}) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { data } = useSelector((state) => state.user);

  // Cleanup preview URL when component unmounts or preview changes
  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (data.usage?.[creditType] === 0) {
        setError("You have no credits left. Please upgrade to continue.");
        return;
      }

      try {
        const selectedFile = acceptedFiles[0];
        if (!selectedFile) {
          throw new Error("No file selected");
        }

        setError(""); // Clear any previous errors
        setLoading(true);

        // Clean up previous preview if exists
        if (preview) {
          URL.revokeObjectURL(preview);
        }

        // Process HEIC/HEIF images
        let processedFile = selectedFile;
        if (selectedFile.type === "image/heic" || selectedFile.type === "image/heif" || 
            selectedFile.name.toLowerCase().endsWith('.heic') || selectedFile.name.toLowerCase().endsWith('.heif')) {
          setConverting(true);
          processedFile = await convertHeicToJpeg(selectedFile);
          setConverting(false);
        }
        
        // Create and set preview immediately after conversion
        const previewUrl = URL.createObjectURL(processedFile);
        setPreview(previewUrl);
        setFile(processedFile);
        
        const formData = new FormData();
        formData.append("profileImage", processedFile);

        const response = await api.post("/api/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.extractedText) {
          setExtractedText(response.data.extractedText);
        } else {
          throw new Error("No text detected in the image. Please upload a dating profile with visible text.");
        }
      } catch (err) {
        console.error("Upload error:", err);
        
        // Get error message from response if available
        const errorMessage = err.response?.data?.message || err.message;
        setError(errorMessage);
        
        // Don't clear preview if it's just a text extraction error
        if (!errorMessage.includes("No text detected")) {
          if (preview) {
            URL.revokeObjectURL(preview);
          }
          setFile(null);
          setPreview(null);
        }
        setExtractedText("");
      } finally {
        setLoading(false);
        setConverting(false);
      }
    },
    [data.usage, creditType, setExtractedText, convertHeicToJpeg, preview]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "image/webp": [],
      "image/heic": [],
      "image/heif": [], // Added HEIF support
    },
    maxFiles: 1,
    multiple: false,
  });

  const handleRemoveImage = useCallback(
    (e) => {
      e.stopPropagation();
      if (preview) {
        URL.revokeObjectURL(preview);
      }
      setFile(null);
      setPreview(null);
      setExtractedText("");
      setError("");
    },
    [preview, setExtractedText]
  );

  return (
    <>
      {data.usage?.iceBreaker === 0 ? (
        <UpgradePrompt />
      ) : (
        <>
          <UploadBox {...getRootProps()}>
            <input {...getInputProps()} />
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <CloudUploadIcon
                  sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  {file ? file.name : "Drag & drop your dating profile screenshot here"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  or
                </Typography>
                <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
                  Select File
                </Button>
                {converting && (
                  <Typography color="primary" sx={{ mt: 2 }}>
                    Converting iPhone image format...
                  </Typography>
                )}
              </>
            )}
            {error && (
              <Typography 
                color="error" 
                sx={{ 
                  mt: 2,
                  backgroundColor: 'rgba(211, 47, 47, 0.1)',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  display: 'inline-block'
                }}
              >
                {error}
              </Typography>
            )}
          </UploadBox>
          {preview && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ position: "relative", height: 200 }}>
                  <ImagePreview src={preview} alt="Uploaded image" />
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={handleRemoveImage}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                      },
                    }}
                  >
                    Remove
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ImageUpload;
