import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import { setModelsOnly } from "./model";

const initialState = {
  isFetched: false,
  isAuthenticated: false,
  isLoading: false,
  isOnboarded: false,
  data: {},
  error: null,
};

const localToken = localStorage?.getItem("token");

export const asyncGetUser = createAsyncThunk(
  "user/getUser",
  async ({ userToken }, { rejectWithValue, dispatch }) => {
    try {
      const token = localToken || userToken;
      if (!token) {
        window.location.href = "/login";
        return initialState;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const models = response.data?.models ?? [];
      dispatch(setModelsOnly(models));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = { ...state.data, ...action.payload?.data };
      state.isLoading = false;
      state.error = null;
    },
    setRemainingToken: (state, action) => {
      const _num = action.payload?.numberOfToken;
      const numberOfToken = typeof _num === "number" ? _num : 0;

      switch (action.payload?.creaditKey) {
        case "credits.modelTraining":
          state.data.credits.modelTraining -= numberOfToken;
          break;
        case "credits.imageGeneration":
          state.data.credits.imageGeneration -= numberOfToken;
          break;
        case "usage.datingBioGenerator":
          state.data.usage.datingBioGenerator -= numberOfToken;
          break;
        case "usage.iceBreaker":
          state.data.usage.iceBreaker -= numberOfToken;
          break;
        case "usage.profileRater":
          state.data.usage.profileRater -= numberOfToken;
          break;
        case "usage.replyEnhancer":
          state.data.usage.replyEnhancer -= numberOfToken;
          break;
        default:
          return state;
      }
    },

    logOut: (state, action) => {
      state = initialState;
      localStorage?.removeItem("token");
      window.location.href = "/login";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncGetUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isAuthenticated = true;
      state.isOnboarded = true;
      state.error = null;
      state.isFetched = true;
    });
    builder.addCase(asyncGetUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isFetched = true;
    });
  },
});

export const userSelector = (state) => state.user;

export const getIsOnboarded = createSelector([userSelector], (state) => {
  const data = state?.data;
  if (!isEmpty(data)) {
    const onboarding = data?.onboarding;
    const isComplate = Object.values(onboarding).every(
      (value) => value?.completed === true
    );
    return { onboarded: Boolean(isComplate), step: 1 };
  } else {
    return { onboarded: false };
  }
});

export const { setUser, setRemainingToken, logOut } = userSlice.actions;

export default userSlice.reducer;
